$(document).ready(function () {
	"use strict"; // start of use strict

	/*==============================
	Menu
	==============================*/
	$('.header__btn').on('click', function(e) {
		e.stopPropagation();
		$(this).toggleClass('header__btn--active');
		$('.header__menu').toggleClass('header__menu--active');
		$('.header').toggleClass('active');
		$('.header__btn').toggleClass('active');
		$('body').toggleClass('bm_active');
	});
	$('.mode_checkbox').on('click', function(e) {
		e.stopPropagation();
	});
	$(document).click(function (e) {
		if ($('.header__btn').hasClass('header__btn--active') == true) {
			$('.header__menu').toggleClass('header__menu--active');
			$('.header__btn').toggleClass('header__btn--active');
			$('body').css('overflow','auto');
		}
	});

	$('.header__search .close, .header__action--search button').on('click', function() {
		$('.header__search').toggleClass('header__search--active');
	});

	/*==============================
	Multi level dropdowns
	==============================*/
	$('ul.dropdown-menu [data-toggle="dropdown"]').on('click', function(event) {
		event.preventDefault();
		event.stopPropagation();

		$(this).siblings().toggleClass('show');
	});

	$(document).on('click', function (e) {
		$('.dropdown-menu').removeClass('show');
	});

	/*=== cart script ===*/
	$('#cart_button').click(function () {
		$('#cart_wrapper').toggleClass('active');
		$('.cart-bg').toggleClass('active');
		$('body').css("overflow", "hidden");
	});
	$('.cart-bg, .cart-section-close').click(function () {
		$('#cart_wrapper').toggleClass('active');
		$('.cart-bg').toggleClass('active');
		$('body').css("overflow", "");
	});

	/*=== Banner video script ===*/
	$('#play-audio-id').click(function(){
        if($('#banner-video-id').prop('muted')){
			$('#banner-video-id').prop('muted',false);
			$('#play-audio-id').html('<i class="material-icons-outlined">volume_up</i>')
        }
        else {
			$('#banner-video-id').prop('muted',true);
			$('#play-audio-id').html('<i class="material-icons-outlined">volume_off</i>')
        }
    });

	/*==============================
	Carousel
	==============================*/
	$('.main__carousel--collections').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: true,
		loop: true,
		autoplay: false,
		smartSpeed: 600,
		margin: 20,
		autoHeight: true,
		responsive: {
			0 : {
				items: 2,
			},
			576 : {
				items: 2,
			},
			768 : {
				items: 3,
				margin: 30,
			},
			992 : {
				items: 4,
				margin: 30,
			},
			1200 : {
				items: 5,
				margin: 30,
				mouseDrag: false,
				dots: false,
			},
		}
	});
	$('#category_carousel').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: false,
		loop: true,
		nav: true,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		smartSpeed: 600,
		margin: 15,
		autoHeight: true,
		responsive: {
		  0 : {
			items: 1,
		  },
		  576 : {
			items: 2,
		  },
		  768 : {
			items: 3,
			margin: 15,
		  },
		  992 : {
			items: 4,
			margin: 15,
		  },
		  1280 : {
			items: 5,
			margin: 15,
		  },
		  1600 : {
			items: 6,
			margin: 15,
		  }
		}
	  });
	$('.featured_carousel').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: true,
		loop: true,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		smartSpeed: 600,
		margin: 10,
		autoHeight: true,
		responsive: {
			0 : {
				items: 1,
			},
			576 : {
				items: 2,
			},
			768 : {
				items: 3,
				margin: 20,
			},
			992 : {
				items: 4,
				margin: 20,
			},
			1280 : {
				items: 5,
				margin: 20,
				mouseDrag: false,
				dots: false,
			},
      1360 : {
        items: 6,
        margin: 20,
        mouseDrag: false,
        dots: false,
      },
		}
	});
$('.featured_carousel').owlCarousel({
    mouseDrag: true,
    touchDrag: true,
    dots: true,
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 5000,
    smartSpeed: 600,
    margin: 20,
    autoHeight: true,
    responsive: {
      0 : {
        items: 1,
      },
      576 : {
        items: 2,
      },
      768 : {
        items: 3,
        margin: 30,
      },
      992 : {
        items: 4,
        margin: 30,
      },
      1200 : {
        items: 6,
        margin: 30,
        mouseDrag: false,
        dots: false,
      },
    }
  });
	$('.main__carousel--explore').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: true,
		loop: true,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		smartSpeed: 600,
		margin: 20,
		autoHeight: true,
		responsive: {
			0 : {
				items: 1,
			},
			576 : {
				items: 2,
			},
			768 : {
				items: 3,
				margin: 30,
			},
			992 : {
				items: 4,
				margin: 30,
			},
			1200 : {
				items: 4,
				margin: 30,
				mouseDrag: false,
				dots: false,
			},
		}
	});

	$('.carousel-collection').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: true,
		loop: true,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		smartSpeed: 600,
		margin: 20,
		autoHeight: true,
		responsive: {
			0 : {
				items: 1,
			},
			576 : {
				items: 2,
			},
			768 : {
				items: 3,
				margin: 30,
			},
			992 : {
				items: 4,
				margin: 30,
			},
			1200 : {
				items: 4,
				margin: 30,
				mouseDrag: false,
				dots: false,
			},
		}
	});

	$('.card__cover--carousel').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: true,
		loop: true,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		autoplaySpeed: 800,
		smartSpeed: 800,
		margin: 20,
		items: 1,
	});

	/*==============================
	Navigation
	==============================*/
	$('.main__nav--prev').on('click', function() {
		var carouselId = $(this).attr('data-nav');
		$(carouselId).trigger('prev.owl.carousel');
	});
	$('.main__nav--next').on('click', function() {
		var carouselId = $(this).attr('data-nav');
		$(carouselId).trigger('next.owl.carousel');
	});

	/*==============================
	Partners
	==============================*/
	$('.partners').owlCarousel({
		mouseDrag: false,
		touchDrag: false,
		dots: false,
		loop: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		smartSpeed: 600,
		margin: 20,
		responsive : {
			0 : {
				items: 2,
			},
			576 : {
				items: 3,
				margin: 20,
			},
			768 : {
				items: 4,
				margin: 30,
			},
			992 : {
				items: 4,
				margin: 30,
			},
			1200 : {
				items: 6,
				margin: 30,
			},
			1900 : {
				items: 8,
				margin: 30,
			},
		}
	});

	/*==============================
	Modal
	==============================*/
	$('.open-video, .open-map').magnificPopup({
		closeOnBgClick: false ,
		enableEscapeKey: false,
		disableOn: 0,
		fixedContentPos: true,
		type: 'iframe',
		preloader: false,
		removalDelay: 300,
		mainClass: 'mfp-fade',
		closeOnBgClick: false ,
		enableEscapeKey: false,
	});

	$('.asset__img').magnificPopup({
		closeOnBgClick: false ,
		enableEscapeKey: false,
		fixedContentPos: true,
		type: 'image',
		closeOnContentClick: true,
		closeBtnInside: false,
		mainClass: 'my-mfp-zoom-in',
		closeOnBgClick: false ,
		enableEscapeKey: false,
		image: {
			verticalFit: true
		},
		zoom: {
			enabled: true,
			duration: 400
		}
	});

	$('.open-modal').magnificPopup({
		closeOnBgClick: false ,
		enableEscapeKey: false,
		fixedContentPos: true,
		fixedBgPos: true,
		overflowY: 'auto',
		type: 'inline',
		preloader: false,
		focus: '#username',
		modal: false,
		removalDelay: 300,
		mainClass: 'my-mfp-zoom-in',
		closeOnBgClick: false ,
		enableEscapeKey: false,
	});

	$('body').delegate(".modal__close", 'click', function (e) {
		e.preventDefault();
		$.magnificPopup.close();
	});

	/*==============================
	Select
	==============================*/
	$('.main__select').select2({
		minimumResultsForSearch: Infinity
	});

	/*==============================
	Section bg
	==============================*/
	$('.main__video-bg, .author__cover--bg, .main__author, .collection__cover').each(function(){
		if ($(this).attr('data-bg')){
			$(this).css({
				'background-image': 'url(' + $(this).data('bg') + ')',
				'background-position': 'center center',
				'background-repeat': 'no-repeat',
				'background-size': 'cover'
			});
		}
	});

	/*==============================
	Upload file
	==============================*/
	$('.sign__file-upload').on('change', function() {
		var videoLabel  = $(this).attr('data-name');

		if ($(this).val() != '') {
			$(videoLabel).text($(this)[0].files[0].name);
		} else {
			$(videoLabel).text('Upload file');
		}
	});

	/*==============================
	Countdown
	==============================*/
	$('.asset__clock').countdown('2022/12/01', function(event) {
		$(this).html(event.strftime('%D days %H:%M:%S'));
	});

    /*==============================
     Scrollbar
     ==============================*/
    /*var Scrollbar = window.Scrollbar;

    if ($('#asset__actions--scroll').length) {
        Scrollbar.init(document.querySelector('#asset__actions--scroll'), {
            damping: 0.1,
            renderByPixels: true,
            alwaysShowTracks: true,
            continuousScrolling: false,
        });
    }*/

    function readURLSingle(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function(e) {
                $('#my-preview-section').html('<img id="img_prv" width="300" height="300" src="preview.img" controls/>')
                $('#img_prv')[0].src = URL.createObjectURL(input.files[0]);
                $('#my-preview-section').hide();
                $('#my-preview-section').fadeIn(650);
                }
            reader.readAsDataURL(input.files[0]);
        }
    }

    function readURLSingleAudio(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function(e) {
//	            $('#my-preview-section').css('background-image', 'url(/assets/audio-file-icon.png)');
                $('#my-preview-section').hide();
                $('#my-preview-section').fadeIn(650);

                $('#my-preview-section').html('<audio width="300" height="300" controls><source src="mov_bbb.mp4" id="audio_here"> </audio>')
                $('#audio_here')[0].src = URL.createObjectURL(input.files[0]);
                $('#audio_here').parent()[0].load();

                $('#imagePreviewRes').css({
                    'width': '100px',
                    'height': '100px',
                    'background-size': 'cover',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center',
                    'margin-left': 'auto',
                    'margin-right': 'auto',
                });
                $('#close-preview-button').css('display', 'inline-block');
                $('#imagePreviewRes').css('background-image', 'url(/assets/audio-file-icon.png)');
                $('#placeholder').fadeOut(100);
                $('#file-1').fadeOut(100);
                $('#choose_file_selected').fadeOut(100);
                $('#imagePreviewRes').hide();
                $('#imagePreviewRes').fadeIn(650);
            }
            reader.readAsDataURL(input.files[0]);
        }
    }

    function readURLSingleVideo(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function(e) {

//                $('#my-preview-section').css('background-image', 'url(/assets/video-file-icon.png)');
                $('#my-preview-section').hide();
                $('#my-preview-section').fadeIn(650);

                $('#my-preview-section').html('<video width="300" height="300" controls><source src="mov_bbb.mp4" id="video_here"> </video>')
                $('#video_here')[0].src = URL.createObjectURL(input.files[0]);
                $('#video_here').parent()[0].load();

                $('#imagePreviewRes').css({
                    'width': '100px',
                    'height': '100px',
                    'background-size': 'cover',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center',
                    'margin-left': 'auto',
                    'margin-right': 'auto',
                });
                $('#close-preview-button').css('display', 'inline-block');
                $('#imagePreviewRes').css('background-image', 'url(/assets/video-file-icon.png)');
                $('#placeholder').fadeOut(100);
                $('#file-1').fadeOut(100);
                $('#choose_file_selected').fadeOut(100);
                $('#imagePreviewRes').hide();
                $('#imagePreviewRes').fadeIn(650);
            }
            reader.readAsDataURL(input.files[0]);
        }
    }

    // $("#file-1").change(function(e) {
    //     var file = e.currentTarget.files[0];
    //     if (file.type == "audio/mpeg") {
    //         readURLSingleAudio(this,'audio');
    //     }if (file.type == "video/mp4") {
    //         readURLSingleVideo(this,'audio');
    //     }else{
    //         readURLSingle(this);
    //     }

    // });

    /*==============================
     Number field restriction
     ==============================*/

	$('.validNumber').on('input', function() {
		this.value = this.value.replace(/[^0-9.]/g, '');
		this.value = this.value.replace(/(\..*)\./g, '$1');
	});

	$('.validInteger').on('input', function(e) {
		this.value = this.value.replace(/[^0-9]/g, '');
	});
/*
	var grid_type;
	grid_type = localStorage.getItem('mode');

	if (grid_type === 'grid-2') {
		$('input[name="grid_type"][value="grid-2"]').prop('checked', true);
		localStorage.setItem('mode', 'grid-2');
		$('.grid_col').addClass('col-xl-6');
		$('.grid_col').removeClass('col-xl-3');
		$('.grid_col').removeClass('col-xl-4');
		$('.grid-2').addClass('active');
		$('.grid-4, .grid-3').removeClass('active');
	} 
	else if(grid_type === 'grid-3') {
		$('input[name="grid_type"][value="grid-3"]').prop('checked', true);
		localStorage.setItem('mode', 'grid-3');
		$('.grid_col').addClass('col-xl-4');
		$('.grid_col').removeClass('col-xl-6');
		$('.grid_col').removeClass('col-xl-3');
		$('.grid-3').addClass('active');
		$('.grid-4, .grid-2').removeClass('active');
	}
	else {
		$('input[name="grid_type"][value="grid-4"]').prop('checked', true);
		localStorage.setItem('mode', 'grid-4');
		$('.grid_col').addClass('col-xl-3');
		$('.grid_col').removeClass('col-xl-4');
		$('.grid_col').removeClass('col-xl-6');		
		$('.grid-4').addClass('active');
		$('.grid-3, .grid-2').removeClass('active');
	}
	
	$('input[name="grid_type"]').click(function() {
		var grid_type;
		grid_type = $('input[name="grid_type"]:checked').val();
		alert(grid_type);
		if (grid_type === 'grid-4') {
			localStorage.setItem('mode', $(this).val());
			$('.grid_col').addClass('col-xl-3');
			$('.grid_col').removeClass('col-xl-4');
			$('.grid_col').removeClass('col-xl-6');	
			$('.grid-4').addClass('active');
			$('.grid-3, .grid-2').removeClass('active');
		} 
		else if(grid_type === 'grid-3') {
			localStorage.setItem('mode', $(this).val());
			$('.grid_col').addClass('col-xl-4');
			$('.grid_col').removeClass('col-xl-6');
			$('.grid_col').removeClass('col-xl-3');
			$('.grid-3').addClass('active');
			$('.grid-4, .grid-2').removeClass('active');
		}
		else {
			localStorage.setItem('mode', $(this).val());
			$('.grid_col').addClass('col-xl-6');
			$('.grid_col').removeClass('col-xl-3');
			$('.grid_col').removeClass('col-xl-4');
			$('.grid-2').addClass('active');
			$('.grid-4, .grid-3').removeClass('active');
		}			  
	});
*/

    /*==============================
     menu fix
     ==============================*/

	 $(window).scroll(function(e){ 
		if ($(this).scrollTop() > 80 ) {  $('.header').addClass( "header-fixed" ); } 
	}); 
		$(window).scroll(function(e){ 
		if ($(this).scrollTop() < 80) { $('.header').removeClass( "header-fixed" );  }  
	});

	var height = $(".cp_after div").height();
	if(height>73) {
		$('.see-more-btn').addClass("active");
	}
	$('.see-more-btn').on('click', function(e) {
		$('.cp_after').addClass("active");
		$('.cp_before').addClass("hide");
		$('.see-more-btn').removeClass("active");
		$('.see-less-btn').addClass("active");
	});
	$('.see-less-btn').on('click', function(e) {
		$('.cp_after').removeClass("active");
		$('.cp_before').removeClass("hide");
		$('.see-more-btn').addClass("active");
		$('.see-less-btn').removeClass("active");
	});

	$('#video_next_btn').on('click', function(e) {
		$('#video_guide').addClass("hide");
		$('.video_lable').addClass('completed');
		$('.video_lable').removeClass('active');
		$('#reading_metrial_guide').removeClass("hide");
		$('.reading_box').removeClass("disable_class");
		$('.reading_lable').addClass("active");
	});

	$('#reading_previous_btn').on('click', function(e) {
		$('#video_guide').removeClass("hide");
		$('.video_lable').removeClass('completed');
		$('.video_lable').addClass('active');
		$('#reading_metrial_guide').addClass("hide");
		$('.reading_box').addClass("disable_class");
		$('.reading_lable').removeClass("active");
	});

	$('#reading_next_btn').on('click', function(e) {
		$('#quiz_guide').removeClass("hide");
		$('.quiz_lable').addClass('active');
		$('.quiz_box').removeClass("disable_class");
		$('#reading_metrial_guide').addClass("hide");
		$('.reading_lable').removeClass("active");
		$('.reading_lable').addClass('completed');
	});

	$('.submitQuizzForm').on('click', function(e){
		e.preventDefault();
		var $form = $("#quizzes_form");
		var request = $.ajax({
			url: $form.attr("action"),
			method: $form.attr("method"),
			dataType: "script",
			data: $form.serializeArray(),
			async: false,
			dataType: "json"
		  });
		  request.done(function (exam) {
			if (exam.status){
			   $('#quiz_guide').addClass('hide')
               $('#examPassPage').removeClass('hide')
			   $('.result_field').removeClass('disable_class')
			   $('.result_lable').addClass('active')
			   $('.quiz_lable').removeClass('active');
			   $('.quiz_lable').addClass('completed');
			   $('#pass_mark_leval').html(numToString(exam.pass_mark));
			   $('.result_lable').addClass('completed')
			}else{
				$('#quiz_guide').addClass('hide')
				$('#examFailPage').removeClass('hide')
				$('.result_field').removeClass('disable_class')
			   $('.result_lable').addClass('active')
			   $('.quiz_lable').removeClass('active');
			   $('.quiz_lable').addClass('completed');
			   $('.result_lable').addClass('completed')
			   $('#fail_mark_leval').html(numToString(exam.pass_mark));
			}
			$("#pass_of_result").html(numToString(exam.pass_mark));
			$("#fass_of_result").html(numToString(exam.fail_mark));
		  });
		  request.fail(function (jqXHR, textStatus) {
		  });
	});

	window.nftave_academy_mint = async function(userAddress, meta_hash, per_price, mint_price, nonce, reward, is_academy, network_id){
		var ethBal = await window.ethBalance();
		const chain_id = await getChainId()
		var selectedChainId = parseInt(network_id)
		if (is_academy=='true'){
			return toastr.error('you have already minted');
		}

		if (selectedChainId != chain_id){
			return toastr.error('Switch to ETH network');
		}
		
		if (isGreaterThanOrEqualTo(ethBal, mint_price)){
			hideAll()
			show_modal('#academyMintModal')
			$('.mintProgress').removeClass('hide')
			academyMintSign(userAddress, meta_hash, per_price, mint_price, nonce, reward);
		}else{
			return toastr.error('Insufficient funds');
		}
    }

	window.academyClaimTokens = async function(address, token){
		hideAll()
	    show_modal('#claimTokenModal')
		$('.claimProgress').removeClass('hide')
		claimAVXTokens(address, token);
	}
	
});


