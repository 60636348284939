function getRanking(refresh=true){
	let time_range = $("#analytic-days-filter").find("button.active").html();
	let by = $(".rank_h_btn.active").data("by");
	let sort = ($(".rank_h_btn.active").find('i').text() == 'expand_more') ? true : false 

	let options = {
		refresh: refresh,
		time_range: time_range,
		by: by,
		asc: sort
	}

	$.ajax({
      url: "/rankings",
      type: "get",
      dataType: "script",
      data: options
    })
}

$(document).ready(function(){
	$(".rank_h_btn").on("click", function(){
		$(".rank_h_btn").removeClass('active');
		$(this).addClass('active');

		// keyboard_arrow_up - up
		// expand_more - down
		// unfold_more

		if($(this).find("i").text() == 'unfold_more' || $(this).find("i").text() == 'keyboard_arrow_up'){
			$(this).find("i").text('expand_more')
		}
		else{
			$(this).find("i").text('keyboard_arrow_up')
		}

		let options = {}
		getRanking(true)
	})

	$("#analytic-days-filter button").on("click", function(){
		$("#analytic-days-filter button").removeClass('active');
		$(this).addClass('active');
		getRanking(true);
	});
})