jQuery.fn.extend({
  activeStep: function() {
    return $(this).find(".follow-step-block.active");    
  },
  start: function() {
    this.hideAll();
    this.activeStep().find(".allProgress").removeClass("hide");
  },
  failure: function() {  
    this.hideAll();  
    this.activeStep().find(".allRetry").removeClass("hide");
  },
  success: function() {
    this.hideAll();
    this.activeStep().find(".allDone").removeClass("hide");
    this.startNextStep();
  },
  startNextStep: function() {
    if(this.activeStep().parent().next().length > 0) {
      this.activeStep().removeClass("active").parent().next().find(".follow-step-block").addClass("active");
      this.start();
    }
    else if(this.activeStep().parent().next().length == 0 && $.magnificPopup){
      $.magnificPopup.close();
      $(this).remove()
    }
  },
  removeStep: function(step) {
    if(this.activeStep().parent().hasClass(step)){
      this.startNextStep();  
    }
    this.find("." + step).remove();
  },
  hideAll: function() {
    const findBlock = this.activeStep();
    findBlock.find("i,a").addClass("hide");    
  }
});

window.loadSteps = function loadSteps(id, steps){
  var request = $.ajax({
    url: '/load_steps',
    async: false,
    type: "GET",
    data: { 
      modalId: id,
      steps: steps
    },
    dataType: "script",
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    }
  });

  request.done(function (msg) {
    if (!$.magnificPopup.instance.isOpen){
      $.magnificPopup.close();
    }
    $.magnificPopup.open({
      closeOnBgClick: false ,
      enableEscapeKey: false,
      items: {
        src: "#"+id
      },
      type: 'inline'
    });
    console.log("Token Id updated.");
  });
  
}


window.modalSteps = (function(){
  var instance;
  var modalId;

  function init(id){
    modalId = "#"+id;

    function start(){
      $(modalId).start();
    }

    function failure(){
      $(modalId).failure();
    }

    function success(){
      $(modalId).success();
    }

    function removeStep(step){
      $(modalId).removeStep(step);
    }

    return {
      start: start,
      success: success,
      failure: failure,
      removeStep: removeStep
    };
  }

  return {
    load: function(id, steps){
      if(!instance){
        instance = init(id);
      }

      // loadSteps('deployMinterModal', ['addMinter'])
      loadSteps(id, steps);
      return instance;
    }
  };

})();

/*
// to laod a model, call modalSteps.load(id, steps)
//define the step locals in en.yml under modal: key

eg:
id = 'deployMinterModal';
steps = ['convertToken', 'sign', 'deploy']
modal_steps = modalSteps.load('deployContract', [])

eg step locals for en.yml:
en:
  modal:
    deploy_minter_modal:
      title: Deploy Minter
      steps:
        convert_token:
          title: Convert token
          caption: converting token
        sign:
          title: Sign
          caption: Adding digital signature
        deploy:
          title: Deploy Minter
          caption: deploying minter
//how to use
id = 'deployMinterModal';
steps = ['convertToken', 'sign', 'deploy']
modal_steps = modalSteps.load('deployContract', [])

// if the step is failed, call failure method
  modal_steps.failure()

// If the step is success, call success method. It will automatically move to next step.
// If there is no next step, then it will close the modal.
  modal_steps.failure() 

*/
