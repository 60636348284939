$(document).ready(function () {

  $(document).on("change", "#collection-put-on-sale", function () {
    if (!$(this).is(":checked")) {
      $('#collection_instant_sale_enabled').prop("checked", false).change();
      $('#collection-unlock-on-purchase').prop("checked", false).change();
    }
  })

  $(document).on("change", "#collection_instant_sale_enabled", function () {
    if ($(this).is(":checked")) {
      $("#instPrice").removeClass("hide")
    } else {
      $("#instPrice").addClass("hide")
    }
  });

  $(document).on("change", "#collection-unlock-on-purchase", function () {
    if ($(this).is(":checked")) {
      $(".unlock-description-section").removeClass("hide")
    } else {
      $(".unlock-description-section").addClass("hide")
    }
  });

  $("#collection_instant_sale_enabled").click(function(event) {
    if ($("#collection_timed_auction_enabled").is(":checked")) {
      toastr.error("Instant sale price can't be enabled while timed auction is enabled");
      event.preventDefault();
    }
  })

  $(document).on("change", "#collection-put-on-sale", function () {
    if (!$(this).is(":checked")) {
      $('#collection_instant_sale_enabled').prop("checked", false).change();
      $('#collection-unlock-on-purchase').prop("checked", false).change();
    }
  })

  $(document).on("change", "#collection_instant_sale_enabled", function () {
    if ($(this).is(":checked")) {
      $("#instPrice").removeClass("hide")
    } else {
      $("#instPrice").addClass("hide")
    }
  });

  $(document).on("change", "#collection-unlock-on-purchase", function () {
    if ($(this).is(":checked")) {
      $(".unlock-description-section").removeClass("hide")
    } else {
      $(".unlock-description-section").addClass("hide")
    }
  });

  $('#collection_timed_auction_enabled').click(function(event) {
    if ($("#collection_instant_sale_enabled").is(":checked")) {
      toastr.error("Timed auction can't be enabled while instant sale price is enabled");
      event.preventDefault();
    } else {
      if ($(this).is(":checked")) {
        // $("#instant_sale").css("pointer-events","none");
        $("#collection_minimum_bid").closest("li").removeClass("hide")
        $("#collection_bid_time").closest("li").removeClass("hide")
        $("#collection_minimum_bid").prop("required", true)
        $("#collection_bid_time").prop("required", true)
      } else {
        // $("#instant_sale").css("pointer-events","auto");
        $("#collection_minimum_bid").closest("li").addClass("hide")
        $("#collection_bid_time").closest("li").addClass("hide")
        $("#collection_minimum_bid").prop("required", false)
        $("#collection_bid_time").prop("required", false)
      }
    }
  });


  // Collection Attribute Add/Remove section
  function updateJsonField(entryClass) {
    var data = {}
    $.each($(entryClass), function (i, collection) {
      var attrKey = $(collection).find(".attr-key").val()
      var attrVal = $(collection).find(".attr-val").val()
      if (attrKey && attrVal && attrKey.length > 0 && attrVal.length > 0) {
        data[attrKey] = attrVal
      }
    })
    $(".collection-data-val").val(JSON.stringify(data))
  }

  function processAttribute( _this, parentClass = '.collection-attribute-entry') {
    var inputKey = _this.closest(parentClass).find(".attr-key").val()
    var inputVal = _this.closest(parentClass).find(".attr-val").val()
    console.log(inputKey, _this, _this.closest(parentClass))
    if (inputKey.length > 0 && inputVal.length > 0) {
      const nonEmptyKey = $('.attr-key').filter(function () {
        return this.value === ''
      });
      const nonEmptyval = $('.attr-val').filter(function () {
        return this.value === ''
      });

      if (nonEmptyKey.length == 0 && nonEmptyval.length == 0) {
        $('#properties_div').append($('#properties_template').html())
      }
    }
  }

  // Collection Attribute Add/Remove section end
function attributes(_this) {
  if($(_this)?.closest('.attribute-section')?.length) {
    processAttribute($(_this), '.attribute-section', '.attribute-section', '.attribute-section')
  } else {
    processAttribute($(_this))
  }
}
  $(document).on("keydown", ".attr-key, .attr-val", function (e) {
    if (e.which == 9 || e.which == 13) {
      attributes($(this))
      $('#properties_div .sign__label.trait:not(:first)').html('&nbsp;')
    }
  })
  function propertyValidation() {
    const last = $('#properties_div .trait-section:last')
    const val = last.children('.sign__group').children('input.attr-key').val()
    const type = last.children('.sign__group').children('input.attr-val').val()
    if(!type && !val) {
      last.remove()
    }
  }
  // Process and Approve section

  $(document).on("click", ".triggerCollectionValidation", async function (e) {
    e.preventDefault()
    var form = $("#collectionCreateForm")[0]
    var source = $("#collection_source").val();
    if (source == "opensea" || form.checkValidity()) {
      var mintType = $("input[name=chooseMintType]").filter(":checked").val();
      var isImportNft = $('#collection_is_imported').val();
      const network = $('#create-nft-network').val()
      if (!isImportNft){
        if(!network) return toastr.error('Please select network')
      }
      if((isImportNft == undefined ) && (mintType == undefined)){
        return toastr.error('Please select minting type')
      } else {
        if ($('#collection_instant_sale_enabled').is(":checked") && (!validFloat($("#instant-price").val()))) {
          return toastr.error('Please enter valid instant price')
        }  else if ($('#collection_timed_auction_enabled').is(":checked") && (!validFloat($("#collection_minimum_bid").val()))) {
          return toastr.error('Please enter minimum bid')
        } else if ($('#collection_timed_auction_enabled').is(":checked") && (!validFloat($("#collection_bid_time").val()))) {
          return toastr.error('Please enter bid time')
        } else if(!window.shared && mintType == "lazy"){
          return toastr.error('Lazy Minting disabled with Own Contract');
        } else if ((window.contract_type == 'nft1155' ) && !validNum($('#no_of_copies').val())) {
          return toastr.error('Please enter valid no of copies')
        } else if ((window.contract_type == 'nft1155' ) && $("#no_of_copies")[0].validationMessage !== "") {
          return toastr.error("Number of copies " + $("#no_of_copies")[0].validationMessage.toLowerCase())
        } else {
          if($('#collection_instant_sale_enabled').is(":checked") == false) {
            $('#instant-price').val('')
          }
          if($('#collection_timed_auction_enabled').is(":checked") == false) {
            $("#collection_minimum_bid").val('')
            $("#collection_bid_time").val('')
          }
          propertyValidation()
          if (!isImportNft) {
            const status = await createNetworkValidator(() => $('.triggerCollectionValidation').click())
            if(!status) return
          }
          $("#submitCollection").click();
          $("#collectionCreateForm :input").prop("disabled", true);
        }
      }
    } else {
      if ($('#file-1').val() === '') {
        return toastr.error('Please select collection file')
      } else if ($('#collection-name').val() === '') {
        return toastr.error('Please provide collection name')
      } else if ($('#description').val() === '') {
        return toastr.error('Please provide collection description')
      } else if ((window.shared || window.contract_type == 'nft1155' ) && !validNum($('#no_of_copies').val())) {
        return toastr.error('Please enter valid no of copies')
      } else if ((window.shared || window.contract_type == 'nft1155' ) && $("#no_of_copies")[0].validationMessage !== "") {
        return toastr.error("Number of copies " + $("#no_of_copies")[0].validationMessage.toLowerCase())
      } else {
        toastr.error('Please fill all required fields.')
      }
    }
  })

  $(document).on("click", ".collection-submit", function (e) {
    e.preventDefault()
    $(this).text("In Progress");
    $(this).closest(".row").find("status-icon").html('<div class="follow-step-2-icon"><div class="loader"></div></div>')
    $(".collection-submit-btn").click()
  })

  $(document).on("click", ".default-btn", function (e) {
    e.preventDefault()
  })

  $(document).on("click", ".createOwnErc721Form, .deployRetry", function () {
    startContractDeploy($('#collection_contract_type').val(), $('#collection_contract_type').data('factory'))
  });

  async function validateNetwork(selectedChainId, name, callback = '', errorCallBack = null) {
    try {
      const chainId = await getChainId()
      selectedChainId = parseInt(selectedChainId)
      if (selectedChainId === chainId) {
        return true
      }
      console.log({ chainId, selectedChainId, callback })
      clearToastr()
      if(isNaN(chainId)){
        connectToWalletMessage();
      }
      else{
        toastr.error(`Switch to ${name} network`);
      }

      await switchNetwork(selectedChainId, callback, errorCallBack)
    } catch(e) {
      toastr.error(e.message)
    }
    return false
  }
  window.validateNetwork = validateNetwork

  window.startContractDeploy = async function(contractType, factoryAddress) {
    const name = $('#nft_contract_name').val();
    const symbol = $('#nft_contract_symbol').val();
    if(!await validateNetwork($('#nft-network').val(), $('#nft-network :selected').text(), () => $('.createOwnErc721Form').click())) return

    if (!name || !symbol) {
      toastr.info('Provide valid name and symbol')
      show_modal('#createOwnErc721')
    } else {
      const compiled_details = getContractABIAndBytecode('', contractType, false); //shared=false
      const abi = compiled_details['compiled_contract_details']['abi_factory']
      console.log(abi, name, symbol, contractType)
      contractDeployInit()
      deployContract(abi, name, symbol, contractType, factoryAddress);
    }
  }

  window.contractDeployInit = function() {
    show_modal('#deployContract')
    $('.deployProgress').removeClass('hide')
    $('.deployDone').addClass('hide')
    $('.deployRetry').addClass('hide')
    $('.signStart').addClass('grey').removeClass('hide')
    $('.signProgress').addClass('hide')
    $('.signRetry').addClass('hide')
    $('.signDone').addClass('hide')
  }

  window.contractDeploySuccess = function(contractAddress, contractType) {
    console.log("Contract Address: " + contractAddress);
    $('.deployProgress').addClass('hide')
    $('.deployProgress').addClass('hide')
    $('.deployDone').addClass('disabledLink').removeClass('hide')
    console.log(contractAddress, contractType)
  }

  window.contractDeployFailed = function(errorMsg) {
    toastr.error(errorMsg)
    $('.deployProgress').addClass('hide')
    $('.deployDone').addClass('hide')
    $('.deployRetry').removeClass('hide').addClass('grey')
  }

  async function createNetworkValidator(callback) {
    const network = $('#create-nft-network').val()
    if(!network) return toastr.error('Please select network')
    return await validateNetwork(network, $('#create-nft-network :selected').text(), callback)
  }

  window.initCollectionCreate = async function(contractAddress, contractType) {
    const status = await createNetworkValidator(() => initCollectionCreate($('#collection_contract_address').val(), $('#collection_contract_type').val()))
    if(!status) return
    const existingToken = $("#collection_token").val()
    collectionCreateInit(false, existingToken)
    approveNFT(contractType, contractAddress, window.shared, 'collection', existingToken)
  }

  window.initLazyMint = function(){
    approveCollection($('#collection_id').val());
    if ($('#collection_instant_sale_enabled').is(":checked")){
      collectionCreateInit(true)
      initsignFixedPriceProcess(true)
    }else{
      toastr.success('Collection created successfully.')
      window.location.href = '/collections/' + $('#collection_id').val()
    }
  }

  window.initListingNFT = function initListingNFT(contractAddress, contractType, sharedCollection, existingToken) {
    if ($('#collection_instant_sale_enabled').is(":checked")){
      collectionCreateInit(false, existingToken);
      approveListingNFT(contractType, contractAddress, sharedCollection, 'collection', existingToken);
      initsignFixedPriceProcess(false);
    } else {
      toastr.success('Collection created succcessfully.')
      window.location.href = '/collections/' + $('#collection_id').val()
    }
  }

  window.collectionCreateInit = function(lazy_minting=false, existingToken='') {
    if ($('#collection_instant_sale_enabled').is(":checked")) {
      $('.signFixedPrice').removeClass('hide')
    } else {
      $('.signFixedPrice').addClass('hide')
    }
    show_modal('#collectionStepModal')
    if (existingToken) {
      $('.mintFlow').addClass('hide')
    }
    if(lazy_minting){
      $('.mintFlow').addClass('hide')
      $('.approveFlow').addClass('hide')
    }
    $("#deployContract").modal("hide")
    $("#collectionStepModal").modal("show")
    $('.allProgress').addClass('hide')
    $('.allDone').addClass('hide')
    $('.allRetry').addClass('hide')
    $('.allStart').removeClass('hide').addClass('grey')
    $('.approveProgress').removeClass('hide')
  }

  window.collectionApproveSuccess = function(contractType, existingToken='') {
    mintCollectionCreate(contractType, existingToken)
  }

 async function mintCollectionCreate (contractType, existingToken='') {
    const status = await createNetworkValidator(() => mintCollectionCreate (contractType, existingToken))
    if(!status) return
    $('.allProgress').addClass('hide')
    $('.allDone').addClass('hide')
    $('.allRetry').addClass('hide')
    $('.allStart').addClass('hide').addClass('grey')
    $('.approveDone').removeClass('hide').removeClass('grey').addClass('disabledLink')
    $('.mintProgress').removeClass('hide')
    $('.signFixPriceStart').removeClass('hide').addClass('grey')
    // TODO: WHILE CHANGE NFT TO SHARED/OWNER THS HAS TO BE CHANGED
    if (existingToken) {
      initsignFixedPriceProcess()
    } else {
      const { walletAddresses, royalties } = getMultiRoyalties()
      if (contractType === 'nft721') {
        createCollectible721($('#collection_contract_address').val(), $('#collection_token_uri').val(), walletAddresses, royalties, $('#collection_id').val())
      } else if (contractType === 'nft1155') {
        createCollectible1155($('#collection_contract_address').val(), $('#collection_supply').val(), $('#collection_token_uri').val(),  walletAddresses, royalties, $('#collection_id').val())
      }
    }
  }

  function getMultiRoyalties(){
    let data = $('#collection_multi_royalty').val();
    if (!data) {
      return {
        walletAddresses: [$('#collection_wallet_address').val()],
        royalties: [0]
      }
    }
    data = JSON.parse(data)
    return {
      walletAddresses: data.map(d => d.address),
      royalties: data.map(d => d.percentage)
    }
  }

  window.collectionApproveFailed = function(errorMsg) {
    toastr.error(errorMsg)
    $('.allProgress').addClass('hide')
    $('.allDone').addClass('hide')
    $('.allRetry').addClass('hide')
    $('.allStart').removeClass('hide').addClass('grey')
    $('.approveRetry').removeClass('hide')
  }

  $(document).on("click", ".approveFlow .approveRetry", function () {
    if ($('#priceChange').length) {
      initApproveResale()
    } else {
      initCollectionCreate($('#collection_contract_address').val(), $('#collection_contract_type').val())
    }
  })

  $(document).on("click", ".mintRetry", function () {
    mintCollectionCreate($('#collection_contract_type').val())
  })

  window.collectionMintSuccess = function(collectionId) {
    if ($('#collection_instant_sale_enabled').is(":checked")) {
      $('.mintProgress').addClass('hide')
      $('.mintDone').removeClass('hide')
      initsignFixedPriceProcess()
    } else {
      toastr.success('Collection created successfully.')
      window.location.href = '/collections/' + collectionId
    }
  }

  window.collectionMintFailed = function(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.approveDone').removeClass('hide').removeClass('grey').addClass('disabledLink')
    $('.mintRetry, .signFixPriceStart').removeClass('hide')
  }

  window.initsignFixedPriceProcess = async function(is_lazy_minting=false) {
    hideAll()
    $('.approveDone, .convertDone, .mintDone, .signFixPriceProgress').removeClass('hide')
    var pay_token_address = $('#collection_erc20_token_id option:selected, this').attr('address')
    var details = await fetchCollectionDetails(null, pay_token_address)
    const status = await nftNetwork(initsignFixedPriceProcess, null, details['network'])
    if(!status) return
    if (details) {
      //tokenID is 0 for Lazy-minting blocks
      const tokenId = is_lazy_minting ? 0 : details['token_id']
      signSellOrder(details['unit_price'], details['pay_token_decimal'], details['pay_token_address'],
        tokenId, details['asset_address'], details['collection_id'], details['owned_tokens'])
    } else {
      bidSignFixedFailed('Unable to fetch token details. Please try again later')
    }
  }

  window.bidSignFixedSuccess = function (collectionId) {
    toastr.success('Collection created successfully.')
    window.location.href = '/collections/' + collectionId
  }

  window.bidSignFixedFailed = function (errorMsg) {
    approveFails('.convertDone, .approveDone, .mintDone, .signFixPriceRetry', errorMsg)
  }

  $(document).on("click", ".signFixPriceRetry", function () {
    if($('#priceChange').length){
      initsignFixedPriceUpdate()
    }else{
      initsignFixedPriceProcess($("input[name=chooseMintType]").filter(":checked").val() === 'lazy')
    }
  })

  // BIDDING MODEL STARTS HERE
  // Process and Approve section
  $(document).on("click", ".triggerBiddingValidation", async function (e) {
    clearToastr();
    e.preventDefault()
    if(!await nftNetwork(() => $('.triggerBiddingValidation').click())) return
    var form = $("#biddingForm")[0]
    if ($('#bid_qty').length && !validNum($('#bid_qty').val())) {
      return toastr.error('Please enter valid quantity');
    } else if (!validFloat($('#bid_amt').val())) {
      return toastr.error('Please enter valid price')
    } else if (form.checkValidity()) {
      const minBid = maxBid($('#bid_currency :selected').attr('address'))
      if(minBid) {
        if(minBid.amount >= $('#bid_amt').val()) {
          return toastr.error('Please enter price greater than minimum bid price')
        }
      }
      $("#biddingForm :input").prop("disabled", true);
      initBidProcess();
    } else if ($("#bid_qty")[0].validationMessage !== "") {
      return toastr.error($("#bid_qty")[0].validationMessage)
    }
  })

  $(document).on("click", ".triggerSwapValidation, .approveSwapFeeRetry", function (e) {
    clearToastr();
    e.preventDefault()
    hideAll()
    $('.convertEth').addClass("hide")
    $('.approveSwapFeeProgress, .signSwapStart').removeClass('hide')
    signSwapMethod()
  })

  $(document).on("click", "#swapModal", function (e) {
    e.preventDefault()
    swapModal()
  })

  async function swapModal() {
    if(!await nftNetwork(swapModal)) return
    show_modal('#swap-modal')
  }

  window.signSwapMethod = async function (status = 'approve') {
    if(!await nftNetwork(() => signSwapMethod(status))) return

    var requestor_collection_id = $("input[name=nftradio]:checked").val()
    var requestor_total_quantity = parseInt($("input[name=nftradio]:checked").parent().find('#requestor_total_quantity').text())
    var owner_total_quantity = parseInt($('#owner_total_quantity').text())
    var owner_quantity = parseInt($('#owner_quantity').val() || 1)
    var requestor_quantity = parseInt($("input[name=nftradio]:checked").parent().find('#requestor_quantity').val() || 1)
    var owner_collection_address = $('#collection_id').val()
    var requestor_contract_address = $("input[name=nftradio]:checked").parent().find('#requestor_contract_address').val()
    var requestor_collection_token = $("input[name=nftradio]:checked").parent().find('#requestor_collection_token_id').val()
    var owner_contract_address = $('#owner_contract_address').val()
    var owner_collection_token = $('#owner_collection_token_id').val()
    if(!validNum(requestor_collection_id)){
      return toastr.error('Please select a collection for swapping');
    }
    else if (!validNum(owner_quantity) || !validNum(requestor_quantity) || owner_quantity > owner_total_quantity || requestor_quantity > requestor_total_quantity) {
      return toastr.error('Please enter valid quantity');
    } else if (!owner_collection_address) {
      return toastr.error('Invalid collection address')
    } else if(status == 'convertSuccess') {
      const details = await fetchCollectionDetails(null, gon.tokenAddress)
      let amount = BigNumber(details.unit_price).plus(BigNumber(gon.collection_data.instant_sale_price))
      amount = percentageOf(details.swap_fee, (amount / 2))
      await approveSwapFee(details, amount)
    } else if(status == 'approve') {
      hideAll()
      $('.convertEth').addClass("hide")
      $('.approveSwapFeeProgress, .signSwapStart').removeClass('hide')
      const details = await fetchCollectionDetails(null, gon.tokenAddress)
      await loadTokenBalance(details.pay_token_address, details.pay_token_decimal);
      let amount = BigNumber(details.unit_price).plus(BigNumber(gon.collection_data.instant_sale_price))
      amount = percentageOf(details.swap_fee, (amount / 2))
      const curErc20Balance = await tokenBalance(details.pay_token_address, details.pay_token_decimal)
      console.log({ details, amount, curErc20Balance})

      if (isGreaterThanOrEqualTo(curErc20Balance, amount)) {
        await approveSwapFee(details, amount)
      } else if (gon.tokenAddress == details.pay_token_address && isGreaterThanOrEqualTo(await ethBalance(), amount)) {
        convertCoinToToken(amount - curErc20Balance, 'SwapRequest')
      } else {
        $.magnificPopup.close();
        return toastr.error('Not enough balance')
      }
    } else {
      hideAll()
      $('.approveSwapFeeDone, .signSwapProgress, .convertDone').removeClass('hide')
      await signSwap(requestor_contract_address, requestor_collection_token, owner_contract_address, owner_collection_token, requestor_quantity, owner_quantity);
    }
  }

  async function approveSwapFee(details, amount) {
    show_modal('#placeSwapRequest')
    await approveERC20(details.pay_token_address, amount, details.pay_token_decimal, 'Swap')
  }

  // TODO: WHILE ADDING NEW CUREENCIES HAVE TO MAKE LOGIC TO FETCH DECIMALS HERE
  window.initBidProcess = async function() {
    var curErc20Balance = $('#erc20_balance').text()
    var totalAmt = $("#bid-total-amt-dp").attr('bidAmt')
    var symbol = $('#bid_currency :selected').text();
    if (isGreaterThanOrEqualTo(curErc20Balance, totalAmt)) {
      $('.convertEth').addClass("hide")
      initApproveBidProcess()
    } else if (
      gon.tokenAddress === $('#bid_currency :selected').attr('address') &&
      isGreaterThanOrEqualTo(await ethBalance(), totalAmt)
    ) {
      show_modal('#placeBid')
      convertCoinToToken(totalAmt - curErc20Balance, 'Bid')
    } else {
      $("#biddingForm :input").prop("disabled", false);
      $.magnificPopup.close();
      return toastr.error('Not enough balance')
    }
  }

  window.bidConvertSuccess = function() {
    $('.convertProgress').addClass('hide')
    $('.convertDone').removeClass('hide')
    initApproveBidProcess()
  }

  window.bidConvertFailed = function(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.allStart').removeClass('hide').addClass('grey')
    $('.convertRetry').removeClass('hide')
  }

  window.initApproveBidProcess = async function() {
    hideAll()
    $('.convertDone').removeClass('hide')
    $('.approvebidProgress').removeClass('hide')
    $('.signbidStart').removeClass('hide')
    show_modal('#placeBid', {
      close: function(){
        $("#biddingForm :input").prop("disabled", false);
      }
    })
    const contractAddress = $('#bid_currency option:selected, this').attr('address')
    const decimals = $('#bid_currency option:selected, this').attr('decimals')
    if(!await nftNetwork(initApproveBidProcess, bidApproveFailed)) return
    approveERC20(contractAddress, $("#bid-total-amt-dp").attr('bidAmt'), decimals)
  }

  window.bidApproveSuccess = function() {
    $('.approvebidProgress').addClass('hide')
    $('.approvebidDone').removeClass('hide')
    initSignBidProcess()
  }

  window.bidApproveFailed = function(errorMsg = '') {
    approveFails('.convertDone, .approvebidRetry, .signbidStart', errorMsg)
  }

  $(document).on("click", ".approvebidRetry", initApproveBidProcess)

  async function initSignBidProcess() {
    approveFails('.convertDone, .approvebidDone, .signbidProgress')
    const contractAddress = $('#bid_currency option:selected, this').attr('address')
    const details = await fetchCollectionDetails(null, contractAddress)
    if(!await nftNetwork(initSignBidProcess)) return
    if (details) {
      bidAsset(details['asset_address'], details['token_id'], $("#bid_qty").val(), $("#bid-total-amt-dp").attr('bidAmt'),
        details['pay_token_address'], details['pay_token_decimal'], details['collection_id'], $("#bid-total-amt-dp").attr('bidPayAmt'))
    } else {
      bidSignFailed('Unable to fetch token details. Please try again later')
    }
  }

  window.bidSignSuccess = function(collectionId) {
    toastr.success('Bidding success.')
    window.location.href = '/collections/' + collectionId
  }

  window.bidSignFailed = function(errorMsg) {
    approveFails('.convertDone, .approvebidDone, .signbidRetry', errorMsg)
  }

  $(document).on("click", ".signbidRetry", initSignBidProcess)

  $(document).on("click", ".signSwapRetry", function () {
    var owner_quantity = parseInt($('#owner_quantity').val())
    var requestor_quantity = parseInt($("input[name=nftradio]:checked").parent().find('#requestor_quantity').val())
    var requestor_contract_address = $("input[name=nftradio]:checked").parent().find('#requestor_contract_address').val()
    var requestor_collection_token = $("input[name=nftradio]:checked").parent().find('#requestor_collection_token_id').val()
    var owner_contract_address = $('#owner_contract_address').val()
    var owner_collection_token = $('#owner_collection_token_id').val()
    hideAll()
    $('.approveSwapFeeDone, .signSwapProgress').removeClass('hide')
    show_modal('#placeSwapRequest')
    signSwap(requestor_contract_address, requestor_collection_token, owner_contract_address, owner_collection_token, requestor_quantity, owner_quantity);
  })

  // BUYING MODEL STARTS HERE
  $(document).on("click", ".triggerBuyValidation", async function (e) {
    console.log(gon.collection_data)
    clearToastr();
    e.preventDefault()
    if(!await nftNetwork(() => $('.triggerBuyValidation').click() )) return
    if (!validNum($('#buy_qty').val())) {
      return toastr.error('Please enter valid quantity');
    } else if (!isLessThanOrEqualTo($('#buy_qty').val(), $('#buy_qty').attr('maxQuantity'))) {
      return toastr.error('Maximum quantity available is ' + $('#buy_qty').attr('maxQuantity'))
    } else {
      $("#buyForm :input").prop("disabled", true);
      initBuyProcess();
    }
  })

  window.initBuyProcess = async function() {
    var curErc20Balance = $('#erc20_balance').text()
    var totalAmt = $("#buy-total-amt-dp").attr('buyAmt')
    if (isGreaterThanOrEqualTo(curErc20Balance, totalAmt)) {
      $('.convertEth').addClass("hide")
      initApproveBuyProcess()
    } else if (gon.tokenAddress === $("#buyContractAddress").text() && isGreaterThanOrEqualTo(await ethBalance(), totalAmt)) {
      show_modal('#placeBuy')
      convertCoinToToken(totalAmt - curErc20Balance, 'Buy')
    } else {
      $("#buyForm :input").prop("disabled", false);
      $.magnificPopup.close();
      return toastr.error('Not enough balance');
    }
  }

  window.buyConvertSuccess = function() {
    $('.convertProgress').addClass('hide')
    $('.convertDone').removeClass('hide')
    initApproveBuyProcess()
  }

  window.buyConvertFailed = function(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.allStart').removeClass('hide').addClass('grey')
    $('.convertRetry').removeClass('hide')
  }

  async function initApproveBuyProcess() {
    hideAll()
    const contractAddress = $("#buyContractAddress").text()
    const contractDecimals = $("#buyContractDecimals").text()
    $('.convertDone').removeClass('hide')
    $('.approvebuyProgress').removeClass('hide')
    $('.purchaseStart').removeClass('hide')
    show_modal('#placeBuy', {
      close: function(){
        $("#buyForm :input").prop("disabled", false);
      }
    })
    $('.purchaseAndMintStart').removeClass('hide')
    if(!await nftNetwork(initApproveBuyProcess, buyApproveFailed)) return
    $("#Buy-modal").modal("hide")
    $("#placeBuy").modal("show")
    approveERC20(contractAddress, $("#buy-total-amt-dp").attr('buyAmt'), contractDecimals, 'Buy')
  }

  window.buyApproveSuccess = function(_, contractAddress) {
    $('.approvebuyProgress').addClass('hide')
    $('.approvebuyDone').removeClass('hide')
    initPurchaseProcess(contractAddress)
  }

  window.buyApproveFailed = function(errorMsg = null) {
    if(errorMsg) {  toastr.error(errorMsg) }
    hideAll()
    $('.convertDone').removeClass('hide')
    $('.approvebuyRetry').removeClass('hide')
    $('.purchaseStart').removeClass('hide')
    $('.purchaseAndMintStart').removeClass('hide')
  }

  $(document).on("click", ".approvebuyRetry", initApproveBuyProcess)

  async function initPurchaseProcess(contractAddress) {
    hideAll()
    $('.convertDone').removeClass('hide')
    $('.approvebuyDone').removeClass('hide')
    $('.purchaseProgress').removeClass('hide')
    $('.purchaseAndMintProgress').removeClass('hide')
    if(!await nftNetwork(() => initPurchaseProcess(contractAddress))) return
    var paymentDetails = await fetchCollectionDetails(null, contractAddress)
    if($('#is_collection_lazy_minted').val()=="true"){
        MintAndBuyAsset(paymentDetails['owner_address'], toNum(paymentDetails['asset_type']), paymentDetails['asset_address'],
          paymentDetails['token_id'], toNum(paymentDetails['unit_price']), toNum($('#buy_qty').val()), toNum($("#buy-total-amt-dp").attr('buyAmt')),
          paymentDetails['pay_token_address'], toNum(paymentDetails['pay_token_decimal']),
          paymentDetails['seller_sign'], paymentDetails['collection_id'], paymentDetails['token_uri'], paymentDetails['wallet_address'], paymentDetails['royalties'], paymentDetails['shared'],paymentDetails['total'], paymentDetails['trade_address'])
      }else{
        buyAsset(paymentDetails['owner_address'], toNum(paymentDetails['asset_type']), paymentDetails['asset_address'],
              paymentDetails['token_id'], toNum(paymentDetails['unit_price']), toNum($('#buy_qty').val()), toNum($("#buy-total-amt-dp").attr('buyAmt')),
              paymentDetails['pay_token_address'], toNum(paymentDetails['pay_token_decimal']),
              paymentDetails['seller_sign'], paymentDetails['collection_id'], paymentDetails['encoded_data'] ?? '0x', paymentDetails['asset_supply'], paymentDetails['shared'])
      }
  }

  window.buyPurchaseSuccess = function(collectionId) {
    $('.convertDone').removeClass('hide')
    $('.approvebuyDone').removeClass('hide')
    $('.purchaseProgress').addClass('hide')
    $('.purchaseMintAndProgress').addClass('hide')
    $('.purchaseDone').removeClass('hide')
    $('.purchaseAndMintDone').removeClass('hide')
    toastr.success('Purchase success.')
    window.location.href = '/collections/' + collectionId
  }

  window.buyPurchaseFailed = function(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.convertDone').removeClass('hide')
    $('.approvebuyDone').removeClass('hide')
    $('.purchaseRetry').removeClass('hide')
  }

  window.buyMintAndPurchaseFailed = function(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.convertDone').removeClass('hide')
    $('.approvebuyDone').removeClass('hide')
    $('.purchaseRetry').removeClass('hide')
  }

  $(document).on("click", ".purchaseRetry", function () {
    initPurchaseProcess($("#buyContractAddress").text())
  })

  $(document).on("click", ".execButton", async function (e) {
    e.preventDefault()
    clearToastr();
    const _this = $(this)
    if(!await nftNetwork(() => _this.click())) return

    $('.bidExecDetail').text(_this.attr('bidDetail'))
    $('#bidByUser').text(_this.attr('bidUser'))
    $('.executeBidSymbol').text(_this.attr('bidSymbol'))
    $('#contractAddress').text(_this.attr('contractAddress'))
    $('#erc20ContractAddress').text(_this.attr('erc20ContractAddress'))
    $('#bidId').val(_this.attr('bidId'))
    calculateBidExec(_this)
    show_modal('#bidDetail')
  })

  $(document).on("click", ".viewSwap", function (e) {
    clearToastr();
    var collectionId = e.currentTarget.getAttribute('collectionId')
    $('.ApproveSwap').removeClass('disableLinkButtons')
    console.log(collectionId)
    show_modal('#swapDetail.'+collectionId)
  })

  // EXECUTING BID MODEL HERE
  $(document).on("click", ".triggerExecuteBidValidation", function (e) {
    clearToastr();
    e.preventDefault();
    show_modal('#executeBid')
    initApproveExecBidProcess();
  })

  async function initApproveExecBidProcess() {
    console.log('calling')
    if(!await nftNetwork(initApproveExecBidProcess, approveBidFailed)) return
    approveNFT(
      $('#contractType').text(),
      $('#contractAddress').text(),
      gon.collection_data['contract_shared'],
      'executeBid'
    )
  }

  window.approveBidSuccess = function() {
    initAcceptBidProcess()
  }

  function approveFails(removeClasses, errorMsg = ''){
    if(errorMsg) { toastr.error(errorMsg) }
    hideAll()
    $(removeClasses).removeClass('hide')
  }
  window.approveFails = approveFails
  window.approveBidFailed = function(errorMsg = '') {
    approveFails('.approveExecBidRetry, .acceptBidStart', errorMsg)
  }

  window.swapFailed = function(errorMsg){
    approveFails('.approveSwapFeeRetry, .signSwapStart', errorMsg)
    $('.approveSwapRetry').removeClass('hide')
  }

  window.swapSuccess = function(collectionId){
    toastr.success('Swap Successful.')
    window.location.href = '/collections/' + collectionId
  }

  $(document).on("click", ".approveExecBidRetry", initApproveExecBidProcess)

  window.initAcceptBidProcess = async function() {
    hideAll()
    $('.approveExecbidDone').removeClass('hide')
    $('.acceptBidProgress').removeClass('hide')
    if(!await nftNetwork(initAcceptBidProcess, acceptBidFailed)) return
    var contractAddress = $('#erc20ContractAddress').text();
    var paymentDetails = await fetchCollectionDetails($('#bidId').val(), contractAddress);
    var lazyMint = $('#is_collection_lazy_minted').val();
    var isImported = $('#is_collection_imported').val();
    if(lazyMint=="true")
    {
      $('.MintAndacceptBidProgress').removeClass('hide')
      MintAndAcceptBid(paymentDetails['buyer_address'], toNum(paymentDetails['asset_type']), paymentDetails['asset_address'],
        paymentDetails['token_id'], toNum(paymentDetails['amount_with_fee']), toNum(paymentDetails['quantity']),
        paymentDetails['pay_token_address'], toNum(paymentDetails['pay_token_decimal']),
        paymentDetails['buyer_sign'], paymentDetails['collection_id'], paymentDetails['bid_id'],paymentDetails['token_uri'], paymentDetails['wallet_address'], paymentDetails['royalties'], paymentDetails['shared'],paymentDetails['total'], paymentDetails['trade_address'])
    }else{
      executeBid(paymentDetails['buyer_address'], toNum(paymentDetails['asset_type']), paymentDetails['asset_address'],
            paymentDetails['token_id'], toNum(paymentDetails['amount_with_fee']), toNum(paymentDetails['quantity']),
            paymentDetails['pay_token_address'], toNum(paymentDetails['pay_token_decimal']),
            paymentDetails['buyer_sign'], paymentDetails['collection_id'], paymentDetails['bid_id'], paymentDetails['shared'])
    }
  }

  window.acceptBidSuccess = function(collectionId) {
    hideAll()
    $('.allDone').removeClass('hide')
    toastr.success('Bid accept success.')
    window.location.href = '/collections/' + collectionId
  }

  window.acceptBidFailed = function(errorMsg = '') {
    approveFails('.approveExecbidDone, .acceptBidRetry', errorMsg)
  }

  $(document).on("click", ".acceptBidRetry", initAcceptBidProcess)

  // BUYING MODEL STARTS HERE
  $(document).on("click", ".triggerBurn", function (e) {
    clearToastr();
    e.preventDefault()
    if ($('.burnQuantity').length && !validNum($('.burnQuantity').val())) {
      return toastr.error('Please enter valid quantity')
    } else {
      var form = $("#tokenBurnForm")[0]
      if (form.checkValidity()) {
        show_modal('#burnToken');
        initBurnProcess();
      } else if ($("#burn_qty")[0].validationMessage !== "") {
        return toastr.error($("#burn_qty")[0].validationMessage)
      }
    }
  })

  async function initBurnProcess() {
    hideAll();
    $('.burnProgress').removeClass('hide')
    if(!await nftNetwork(initBurnProcess, burnFailed)) return
    const burnQuantity = $('.burnQuantity').val()
    const paymentDetails = await fetchCollectionDetails()
    burnNFT(paymentDetails['contract_type'], paymentDetails['asset_address'],
      paymentDetails['token_id'], burnQuantity, paymentDetails['collection_id'], paymentDetails['shared'])
  }

  window.burnSuccess = function() {
    $('.burnProgress').addClass('hide')
    $('.burnDone').removeClass('hide')
    toastr.success('Burned successfully.')
    window.location.href = '/'
  }

  window.burnFailed = function(errorMsg = '') {
    approveFails('.burnRetry', errorMsg)
  }

  $(document).on("click", ".burnRetry", initBurnProcess)


  // TRANSFERRING MODEL STARTS HERE
  $(document).on("click", ".triggerTransfer", async function (e) {
    clearToastr();
    e.preventDefault()
    var form = $("#tokenTransferForm")[0]
    if (!isAddress($('#address').val())) {
      return toastr.error('Please enter valid user wallet address');
    }
    const address = fetchTransferDetails()
    const walletAddress = await getCurrentAccount()
    if(address.toLowerCase() == walletAddress.toLowerCase()) {
      return toastr.error('you cant transfer the collection to owned address')
    } else if ($('#transfer_qty').length && !validNum($('#transfer_qty').val())) {
      return toastr.error('Please enter valid quantity')
    } else if (form.checkValidity()) {
      if (address) {
        show_modal('#transferToken');
        initTransferProcess();
      } else {
        return toastr.error('Invalid user address. Please provide address of the user registered in the application')
      }
    } else if ($("#transfer_qty")[0].validationMessage !== "") {
      return toastr.error($("#transfer_qty")[0].validationMessage)
    }
  })

  function fetchTransferDetails() {
    var resp = false
    $.ajax({
      url: '/collections/' + $('#collection_id').val() + '/fetch_transfer_user',
      type: 'GET',
      async: false,
      data: { address: $('.transferAddress').val() },
      success: function (data) {
        if (data.errors) {
          toastr.error(data['error'])
        } else {
          resp = data['address']
        }
      }
    });
    return resp;
  }

  async function initTransferProcess() {
    hideAll()
    $('.transferProgress').removeClass('hide')
    const recipientAddress = $('.transferAddress').val()
    const transferQty = $('.transferQuantity').val()
    if(!await nftNetwork(initTransferProcess, directTransferFailed)) return
    const paymentDetails = await fetchCollectionDetails()
    if (recipientAddress.toLowerCase() == paymentDetails['owner_address']) {
      toastr.error("You can't transfer your own tokens to you. Please try to transfer to another user.");
      $.magnificPopup.close();
    } else {
      directTransferNFT(paymentDetails['contract_type'], paymentDetails['asset_address'], recipientAddress,
        paymentDetails['token_id'], transferQty, gon.collection_data['contract_shared'], paymentDetails['collection_id'])
    }
  }

  window.directTransferSuccess = function(_, collectionId) {
    $('.transferProgress').addClass('hide')
    $('.transferDone').removeClass('hide')
    toastr.success('Transferred successfully.')
    window.location.href = '/collections/' + collectionId
  }

  window.directTransferFailed = function(errorMsg = '') {
    approveFails('.transferRetry', errorMsg)
  }

  $(document).on("click", ".transferRetry", initTransferProcess)
  // PRICECHANGE MODEL STARTS HERE

  $(document).on("click", ".triggerPriceChange", function (e) {
    e.preventDefault()
    initApproveResale()
  })

  window.initApproveResale = async function() {
    if ($('#collection-put-on-sale').is(":checked") || ($('#collection_instant_sale_enabled').is(":checked"))) {
      const network = gon.collection_data.network
      console.log(network)
      if(!await validateNetwork(network.chain_id, network.name, initApproveResale)) return

      if ($('#collection_instant_sale_enabled').is(":checked")) {
        if (!validFloat($("#instant-price").val())) {
          return toastr.error('Please enter valid instant price')
        } else if ($('#collection_timed_auction_enabled').is(":checked") && (!validFloat($("#collection_minimum_bid").val()))) {
          return toastr.error('Please enter minimum bid')
        } else if ($('#collection_timed_auction_enabled').is(":checked") && (!validFloat($("#collection_bid_time").val()))) {
          return toastr.error('Please enter bid time')
        } else if ($('#instant-price').val() !== $('#instant-price').attr('prevVal')) {
          $('.signFixedPrice').removeClass('hide')
        }
      }

      const minBidVal = ($('#collection_timed_auction_enabled').is(":checked") &&
        $('#collection_instant_sale_enabled').is(":checked")) &&
        $("#collection_minimum_bid").val() > $("#instant-price").val()

      if (minBidVal) {
        return toastr.error('Minimum bid cant be more than instant selling price')
      }

      if($('#collection-put-on-sale').is(":checked") && $('#collection_ismultiple').val() == "true") {
        const copies = $('#no_of_copies').val()
        if(copies <= 0) {
          return toastr.error('Please enter valid token numbers')
        }
        const max = Number($('#no_of_copies').attr('max'));
        if(max < copies) {
          return toastr.error('Please enter number of copies less than or equal to ' + max)
        }
      }
      show_modal('#priceChange')
      if ($('#collection-put-on-sale').is(":checked")) {
        $('.approveRetry').addClass('hide')
        $('.approveProgress').removeClass('hide')
        if ($('#collection_instant_sale_enabled').is(":checked")) {
          $('.signFixedPrice').removeClass('hide')
        }
        var details = await fetchCollectionDetails()
        approveResaleNFT(details['contract_type'], details['asset_address'], details['shared'], details['imported'])
      } else {
        hideAll()
        $('.approveFlow').addClass('hide')
        initsignFixedPriceUpdate()
      }
    } else {
      $("#submitPriceChange").click()
    }
  }

  window.approveResaleSuccess = function() {
    hideAll()
    $('.approveDone').removeClass('hide')
    if ($('#collection_instant_sale_enabled').is(":checked")) {
      initsignFixedPriceUpdate()
    } else {
      $("#submitPriceChange").click()
    }
  }

  window.approveResaleFailed = function(errorMsg) {
    toastr.error(errorMsg)
    $('.approveProgress').addClass('hide')
    $('.approveRetry').removeClass('hide')
  }

  window.initsignFixedPriceUpdate = async function() {
    hideAll()
    $('.approveDone, .signFixedPrice, .signFixPriceProgress').removeClass('hide')
    var pay_token_address = $('#collection_erc20_token_id option:selected, this').attr('address')
    var pay_token_decimal = $('#collection_erc20_token_id option:selected, this').attr('decimals')
    const details = await fetchCollectionDetails(null, pay_token_address)
    if(!await nftNetwork(initsignFixedPriceUpdate, null, details['network'])) return
    if (details) {
      signSellOrder($('#instant-price').val(), pay_token_decimal, pay_token_address,
        details['token_id'], details['asset_address'], details['collection_id'], details['owned_tokens'], 'update')
    } else {
      bidSignFixedFailed('Unable to fetch token details. Please try again later')
    }
  }

  window.updateSignFixedSuccess = function() {
    $("#submitPriceChange").click()
  }

  window.updateSignFixedFailed = function(errorMsg) {
    approveFails('.approveDone, .signFixPriceRetry', errorMsg)
  }

  window.updateSignSwapSuccess = async function(signature, nonce) {
    var requestor_collection_id = $("input[name=nftradio]:checked").val()
    var requestor_quantity = $("input[name=nftradio]:checked").parent().find('#requestor_quantity').text()
    var owner_quantity = $('#owner_quantity').text()
    var requestor_quantity = $("input[name=nftradio]:checked").parent().find('#requestor_quantity').val()
    var owner_quantity = $('#owner_quantity').val() || 1
    var owner_collection_address = $('#collection_id').val()
    await createSwap(owner_collection_address, requestor_collection_id, requestor_quantity, owner_quantity, signature, nonce)
    toastr.success('Swap request created.')
    window.location.href = '/collections/' + owner_collection_address
  }

  window.updateSignSwapFailed = function(errorMsg = '') {
    approveFails('.signSwapRetry, .approveSwapFeeDone, .convertDone', errorMsg)
  }

  $(document).on("click", ".rejectSwap", async function (e) {
    var owner_collection_address = $('#collection_id').val()
    var swapId = e.currentTarget.getAttribute('data-swap-id')
    await rejectSwap(owner_collection_address, swapId)
    toastr.error('Swap request rejected.')
    window.location.href = '/collections/' + owner_collection_address
  })

  $(document).on("click", ".approveSwapOwnerFeeRetry", swapAllowance)

  window.approveSwapMethodFail = function(errorMsg){
    approveFails('.convertDone, .approveSwapStart, .approveSwapOwnerFeeRetry', errorMsg)
  }
  window.approveSwapConvertFail = function(errorMsg){
    approveFails('.convertRetry, .approveSwapStart, .approveSwapOwnerFeeStart', errorMsg)
  }

  window.swapApproveFailed = function(errorMsg){
    approveFails('.approveSwapRetry, .approveSwapOwnerFeeDone, .convertDone', errorMsg)
  }

  $(document).on("click", ".convertEthSwap .convertRetry", async function () {
    hideAll()
    $('.convertProgress, .approveSwapOwnerFeeStart, .approveSwapStart').removeClass('hide')
    const swapId = $('#approve_swap_id').val()
    const details = await fetchSwapDetails(swapId)
    const data = await swapFeeAmount(details.requestor_collection_address)
    convertCoinToToken(data.amount - data.curErc20Balance, 'SwapRequestAccept')
  })

  window.swapAllowance = swapAllowance
  async function swapAllowance() {
    if(!await nftNetwork(swapAllowance, () => $('.ApproveSwap').removeClass('disableLinkButtons'))) return
    hideAll()
    show_modal('#approveSwapModal')
    $('.convertEthSwap').addClass("hide")
    $('.convertDone, .approveSwapOwnerFeeProgress, .approveSwapStart').removeClass('hide')
    const swapId = $('#approve_swap_id').val()
    const details = await fetchSwapDetails(swapId)
    const data = await swapFeeAmount(details.requestor_collection_address)
    if (isGreaterThanOrEqualTo(data.curErc20Balance, data.amount)) {
      hideAll()
      $('.convertDone, .approveSwapOwnerFeeProgress, .approveSwapStart').removeClass('hide')
      await approveERC20(data.currentNft.pay_token_address, data.amount, data.currentNft.pay_token_decimal, 'SwapRequestAcceptApprove')
    } else if (gon.tokenAddress == data.currentNft.pay_token_address && isGreaterThanOrEqualTo(await ethBalance(), data.amount)) {
      hideAll()
      $('.convertEthSwap').removeClass("hide")
      $('.convertProgress, .approveSwapOwnerFeeStart, .approveSwapStart').removeClass('hide')
      convertCoinToToken(data.amount - data.curErc20Balance, 'SwapRequestAccept')
    } else {
      $.magnificPopup.close();
      return toastr.error('Not enough balance')
    }
  }

  $(document).on("click", ".ApproveSwap", function (e) {
    const swapId = e.currentTarget.getAttribute('data-swap-id')
    $('#approve_swap_id').val(swapId)
    e.target.classList.add('disableLinkButtons')
    swapAllowance()
  })

  $(document).on("click", ".approveSwapRetry", approveSwapMethod)

  async function swapFeeAmount(nftAddress)
  {
    const details = await fetchSwapPairDetails(nftAddress)
    if(!details?.current) return toastr.error('No data found')
    const currentNft = details?.current
    await loadTokenBalance(currentNft.pay_token_address, currentNft.pay_token_decimal);
    let amount = BigNumber(currentNft.instant_sale_price).plus(BigNumber(details.selected.instant_sale_price))
    amount = percentageOf(details.swap_fee, (amount / 2))
    const curErc20Balance = await tokenBalance(currentNft.pay_token_address, currentNft.pay_token_decimal)
    console.log({ details, amount, curErc20Balance})
    return {
      details,
      amount,
      curErc20Balance,
      currentNft
    }
  }
  window.approveSwapMethod = approveSwapMethod
  async function approveSwapMethod() {
    hideAll()
    $('.approveSwapOwnerFeeDone, .approveSwapProgress').removeClass('hide')
    if(!await nftNetwork(approveSwapMethod)) return
    const owner_collection_address = $('#collection_id').val()
    const swapId = $('#approve_swap_id').val()
    const collectionId = gon.collection_data.collection_id
    const verify_swap = await verifySwap(owner_collection_address, swapId)
    if (verify_swap.success) {
      const details = await fetchSwapDetails(swapId)
      console.log(details['requestor_address'], details['owner_address'],details['requestor_contract_address'],details['requestor_token_id'], details['owner_contract_address'],details['owner_token_id'], details['requestor_quantity'], details['owner_quantity'],details['signature'],details['asset_type'])
      await approveSwap(
        details['requestor_address'],
        details['owner_address'],
        details['requestor_contract_address'],
        details['requestor_token_id'],
        details['owner_contract_address'],
        details['owner_token_id'],
        details['requestor_quantity'],
        details['owner_quantity'],
        details['signature'],
        details['asset_type'],
        swapId,
        collectionId,
        details
      )
    } else {
      toastr.error(verify_swap.errors.join(','))
    }
  }

  // COMMON METHODS FOR BIDDING MODEL
  window.hideAll = function hideAll() {
    $('.allProgress, .allDone, .allRetry, .allStart').addClass('hide')
  }

  $('#createOwnErc721, #deployContract, #collectionStepModal').on('hidden.bs.modal', function () {
    $("#collectionCreateForm :input").prop("disabled", false);
  })

  $(document).on("click", ".collectionModalClose", function () {
    $("#collectionCreateForm :input").prop("disabled", false);
  })

  async function convertCoinToToken(totalAmt, callBackType = null) {
    if(!await nftNetwork(() => convertCoinToToken(totalAmt, callBackType) )) return

    $('.allRetry').addClass('hide')
    $('.convertProgress').removeClass('hide')
    convertToken(totalAmt, callBackType)
  }

  $(document).on("click", ".convertEth .convertRetry", function () {
    if ($("#bid-total-amt-dp").attr('bidAmt') === undefined) {
      show_modal('#placeBuy')
      convertCoinToToken($("#buy-total-amt-dp").attr('buyAmt'), 'Buy')
    } else {
      show_modal('#placeBid')
      convertCoinToToken($("#bid-total-amt-dp").attr('bidAmt'), 'Bid')
    }
  })

  async function nftNetwork(callback, errorCallBack = null, networkData = null) {
    const network = networkData || gon.collection_data.network
    const status = await validateNetwork(network.chain_id, network.name, callback, errorCallBack)
    return status
  }
  window.nftNetworkSwitch = nftNetwork

  function buyNow() {
    console.log($('#buyContractAddress').text())
    window.show_modal('#Buy-modal')
    loadTokenBalance($('#buyContractAddress').text(), $('#buyContractDecimals').text());
  }

  $(document).on("click", ".buy-now", async function (e) {
    e.preventDefault()
    if(!await nftNetwork(buyNow)) return
    buyNow()
  })

  $("#bid_currency").on('change', function () {
    maxBid($('#bid_currency :selected').attr('address'))
  });

  function maxBid(contractAddress) {
    const bid = gon.collection_data.max_bids?.find((d) => d.address == contractAddress )
    $('#minimumBidText').hide()
    if(!bid) return

    $('#minimumBidText').show().children('span').text(`${bid.amount} ${bid.symbol}`)
    return bid
  }

  function bidNow() {
    const sym = $('#bid_currency :selected').text();
    const contractAddress = $('#bid_currency :selected').attr('address');
    maxBid(contractAddress)
    const decimals = $('#bid_currency :selected').attr('decimals');
    window.show_modal('#Bid-modal')
    loadTokenBalance(contractAddress, decimals, sym);
  }

  $(document).on("click", ".bid-now", async function (e) {
    e.preventDefault()
    if(!await nftNetwork(bidNow)) return
    bidNow()
  })

  window.loadTokenBalance = async function(contractAddress, decimals, symbol) {
    var assetBalance = await tokenBalance(contractAddress, decimals);
    $('.ercCurBalance').text(assetBalance);
    $('#erc20_balance').text(assetBalance)
    $("#biding-asset-balance").text(mergeAmountSymbol(assetBalance, symbol));
  }

  async function fetchCollectionDetails(bid_id, erc20_address) {
    var resp = false
    await $.ajax({
      url: '/collections/' + $('#collection_id').val() + '/fetch_details',
      type: 'GET',
      async: false,
      data: { bid_id, erc20_address },
      success: function (respVal) {
        resp = respVal['data']
      }
    });
    console.log(resp)
    return resp;
  }

  async function fetchSwapPairDetails(nft) {
    var resp = false
    await $.ajax({
      url: '/collections/' + $('#collection_id').val() + '/fetch_swap_pair_details',
      type: 'GET',
      data: { nft },
      success: function (respVal) {
        resp = respVal
      }
    });
    return resp;
  }


  async function fetchSwapDetails(swapId) {
    var resp = false
    await $.ajax({
      url: '/collections/' + $('#collection_id').val() + '/fetch_swap_details',
      type: 'GET',
      data: {swap_id: swapId},
      success: function (respVal) {
        resp = respVal['data']
      }
    });
    return resp;
  }

  window.calculateBid = async function(feePercentage) {
    var sym = $('#bid_currency :selected').text();
    var contractAddress = $('#bid_currency :selected').attr('address');
    var decimals = $('#bid_currency :selected').attr('decimals');
    var qty = 1;
    if ($('#bid_qty').val()) {
      qty = $('#bid_qty').val().replace(/[^0-9]/g, '') || 0;
    }
    var price = $('#bid_amt').val().replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') || 0;
    var payAmt = multipliedBy(price, qty)
    var serviceFee = percentageOf(feePercentage, payAmt);
    var totalAmt = plusNum(payAmt, serviceFee);
    $("#bid-amt-dp").html(mergeAmountSymbol(toNum(serviceFee.toFixed(decimals)), sym))
    $("#bid-total-amt-dp").html(mergeAmountSymbol(totalAmt, sym));
    var biddingAssetBalance = await tokenBalance(contractAddress, decimals) || 0;
    $('#erc20_balance').text(biddingAssetBalance);
    $("#biding-asset-balance").text(mergeAmountSymbol(biddingAssetBalance, sym));
    $("#bid-total-amt-dp").attr('bidAmt', totalAmt);
    $("#bid-total-amt-dp").attr('bidPayAmt', payAmt);
  }

  window.calculateBuy = function(feePercentage) {
    var price = $('#buy_price').attr('price');
    var qty = $('#buy_qty').val().replace(/[^0-9]/g, '') || 0;
    var payAmt = multipliedBy(price, qty)
    var serviceFee = percentageOf(feePercentage, payAmt);
    var totalAmt = plusNum(payAmt, serviceFee);
    $("#buy-amt-dp").html(numToString(serviceFee))
    $("#buy-total-amt-dp").html(numToString(totalAmt));
    $("#buy-total-amt-dp").attr('buyAmt', numToString(totalAmt));
  }

  window.calculateBidExec = function(thisBid) {
    var payAmt = thisBid.attr('price');
    var qty = thisBid.attr('qty');
    var serviceFee = $('#sellerFee').text()
    serviceFee = percentageOf(serviceFee, payAmt).toFixed(18);
    var totalAmt = minusNum(payAmt, serviceFee);
    $("#execServiceFee").html(numToString(serviceFee));
    if ($('#royaltyFee').attr('royaltyPercentage')) {
      var royaltyFeePer = $('#royaltyFee').attr('royaltyPercentage')
      var royaltyFee = percentageOf(royaltyFeePer, payAmt)
      $("#executeBidRoyaltyFee").html(royaltyFee);
      var totalAmt = minusNum(totalAmt, royaltyFee);
    }
    $("#executeBidFinalAmt").html(numToString(totalAmt));
  }

  $(document).on("click", ".change-price", function () {
    $(".change-price-modal-title").text($(this).text())
  })

  // Collection - Detail page buy and Place bid button action
  $(document).on("click", ".show-login-message", function (e) {
    e.preventDefault();
    clearToastr()
    connectToWalletMessage();
  });

  $(document).on("click", ".createContractClose", function (e) {
    $("#collectionCreateForm :input").prop("disabled", false);
  });
  $(document).on('click', '.chooseCollectionType', function (e) {
    if($(this).val() == 'create') {
      $('#lazy_minting').hide()
      $('#chooseMintType_mint').prop('checked', true)
    }else {
      $('#lazy_minting').show()
    }
  });
})

function floating(val){
  const regexp = /^\d+\.\d{0,2}$/;
  if(regexp.test(val)){
    return parseFloat(val);
  }
  return parseInt(val) / 10
}

window.connectToWalletMessage = function (){
  toastr.error('Please connect your wallet to proceed.');
}

window.academyMintSuccess = function(userId) {
  $('.mintProgress').addClass('hide')
  $('.mintDone').removeClass('hide')
  toastr.success('Course & Token was successfully purchased')
  window.location.href = '/academy_nft'
}

window.avxClaimSuccess = function(address) {
$('.claimProgress').addClass('hide')
$('.claimDone').removeClass('hide')
toastr.success('AVX Token successfully claimed')
window.location.href = '/academy_nft'
}