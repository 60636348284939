function launchTransak() {
  let transak = new TransakSDK.default({
    apiKey: gon.transak.key, // (Required)
    environment: gon.transak.environment, // (Required)
  });

  transak.init();

  // To get all the events
  transak.on(transak.ALL_EVENTS, (data) => {
    console.log(data);
  });

  // This will trigger when the user closed the widget
  transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {
    transak.close();
  });

  // This will trigger when the user marks payment is made
  transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
    console.log(orderData);
    toastr.success("Transak order succesful");
    transak.close();
  });
}


  $(document).on("click","#addCryptoFunds", function() {
    launchTransak();
  });