import {symbol} from "prop-types";
require('packs/chat.js');

$(document).ready(function () {

    $('#w_mode').on('click', function() {
      var checkBox = document.getElementById("w_mode");
      if (checkBox.checked == true){
      document.cookie= "mode=dark_mode"+";path=/";
      document.body.classList.add('dark_mode');
      document.body.classList.remove('light_mode');
      document.getElementById("mode_css").href="/assets/dark.css";
      }
      else {
      document.cookie= "mode=light_mode"+";path=/";
      document.body.classList.remove('dark_mode');
      document.body.classList.add('light_mode');
      document.getElementById("mode_css").href="/assets/light.css";
      }
    });
    let cookies = document.cookie
    .split(';')
    .map(cookie => cookie.split('='))
    .reduce((accumulator, [key, value]) => ({ ...accumulator, [key.trim()]: decodeURIComponent(value) }), {});
    if (cookies.mode == "dark_mode"){
    document.getElementById("w_mode").checked = true;
    }
    else {
    document.getElementById("w_mode").checked = false;
    }

  $(document).on('click', '.view-notification', function () {
    $.ajax({
      url: "/notifications",
      type: "get",
      dataType: "script",
      data: {}
    })
  })

  $(document).on('click', '.dark-theme-slider', function () {
    lightSelected = $(this).hasClass('lightTheme');
    document.getElementById('themeChange').setAttribute('href', lightSelected ? 'dark' : '');
  });

  $(document).on('click', '.copyUserAddress', function () {
    var copyText = document.getElementById("userAddress");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
    toastr.success('Copied successfully.')
  });

  $(document).on('click', '.search-filter', function(e) {
    searchEvents(e)
  })

  $(document).on('change', '.search-filter-change', function(e) {
    searchEvents(e)
  })

  $(document).on('click', '.search-filter-category', function(e) {
    e.preventDefault();
    explorerFilter('', $(this).data('name'), $('#range-price').val())
  })

  function searchEvents(e) {
    e.preventDefault();
    explorerFilter('', $('#search_category').val(), $('#range-price').val())
  }

  $(document).on('change', '.grid-change', function() {
    $('.grid-change').removeClass('active')
    const grid = $(this).addClass('active')
    $('#search_row > .grid_col')
      .removeClass('col-xl-3 col-xl-4 col-xl-6')
      .addClass(`col-xl-${grid.children('input[name=grid_type]').val()}`)
    explorerFilter('', $('#search_category').val(), $('#range-price').val())
  })

  $(document).on('click', '#onSaleExplorer, #buyNowExplorer, .nft-collection-filter', function(e) {
    explorerFilter('', $('#search_category').val(), $('#range-price').val())
  })

  $(document).on("click", ".dashboard-load-more", function () {
    explorerFilter($(this).data("page-no"), $(this).data("category"), $(this).data("sort-by"))
  });

  $(document).on('change', '#min_explorer, #max_explorer', function(e) {
    const min = $('#min_explorer').val()
    const max = $('#max_explorer').val()
    if(!min || !max) return
    explorerFilter('', $('#search_category').val(), $('#range-price').val())
  })

  async function explorerFilter(page_no, category, sort_by){
    loaderShow()
    const buyNow = $('#buyNowExplorer').is(':checked')
    const onSale = $('#onSaleExplorer').is(':checked')
    const min = $('#min_explorer').val()
    const max = $('#max_explorer').val()
    const gridType = $('.grid-change :checked').val()
    const collections = $('input.nft-collection-filter:checkbox:checked')
      .map(function(_, el) {
        return $(el).val();
      }).toArray()

    await $.ajax({
      url: "/category_filter",
      type: "get",
      dataType: "script",
      data: {
        page_no,
        category,
        sort_by,
        buyNow,
        onSale,
        min,
        max,
        collections,
        gridType
      },complete: function(){
        loaderHide()
      }
    })
  }

  // $(window).scroll(function() {
  //   if ($(window).scrollTop() == $(document).height() - $(window).height()) {
  //     $(".dashboard-load-more").click()
  //     $(".user-load-more").click()
  //   }
  // });

  $(".scrollbar-activity").scroll(function() {
    if ($(".scrollbar-activity").scrollTop() > $(".overall-activities").height() - $(".scrollbar-activity").height()) {
      $(".common-activity-load-more").click()
    }

  })

  $(document).on("click", ".user-load-more", function (e) {
    $.ajax({
      url: "/users/load_tabs",
      type: "get",
      dataType: "script",
      data: {id: $(this).data("id"), page_no: $(this).data("page-no"), tab: $(this).data("tab")}
    })
  });

  $(document).on("click", ".common-activity-load-more", function (e) {
    $.ajax({
      url: "/load_more_activities",
      type: "get",
      dataType: "script",
      data: {id: $(this).data("id"), page_no: $(this).data("page-no"), tab: $(this).data("tab")}
    })
  });

  $(document).on("click", ".dashboard-sort-by", function(e) {
    e.preventDefault()
    var dataParam = {}
    if ($(".top-filter li.active a").data("name")) {
      dataParam["category"] = $(".top-filter li.active a").data("name")
    }

    if ($(this).data("name")) {
      dataParam["sort_by"] = $(this).data("name")
    }

    $.ajax({
      url: "/category_filter",
      type: "get",
      dataType: "script",
      data: dataParam
    })
  })

  $(document).on("click", ".nft-sort-by", function(e) {
    e.preventDefault()
    const dataParam = {}
    const name = $(this).data("name")
    if (name) {
      dataParam["sort_by"] = name
    }
    const query = getParameterByName('query')
    if (query) {
      dataParam["query"] = query
    }
    $.ajax({
      url: "/own_collections_search",
      type: "get",
      data: dataParam,
      success: function(data) {
        $('.filters-content .row').html(data)
        $(".nft-sort-by").removeClass("active")
        $(`*[data-name=`+name+`]`).closest("li>a").addClass("active")
      }
    });
  });

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  preventNegativeNumbers(document.getElementById('royalties'));
  preventNegativeNumbers(document.getElementById('bid_amt'));
  preventNegativeNumbers(document.getElementById('bid_qty'));
  preventNegativeNumbers(document.getElementById('instant-price'));
  preventNegativeNumbers(document.getElementById('buy_qty'));
  preventNegativeNumbers(document.getElementById('transfer-token'));
  preventNegativeNumbers(document.getElementById('burn-tokens'));
  preventNegativeNumbers(document.getElementById('no_of_copies'));
  preventNegativeNumbers(document.getElementsByClassName('only-number'));
  onlyNumberKey(document.getElementById('invitation_qty'))
  $('.timed-action-trigger').each(function () {
    timed_auction_trigger(this)
  });

  const timers = [];

  function timed_auction_trigger(_this){
    timers[_this.id] = setInterval(function() {
      if (_this) {
        const end_time = _this.value
        const address = $(_this).data('address')
        const countDownDate = new Date(end_time).getTime();
        const collectionShowPage = $(_this).data('collection-show')
        let tempArray = [];
        let now = new Date().getTime();
        let distance = countDownDate - now;
        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if(collectionShowPage) {
          $('#tA-days').text(days)
          $('#tA-hours').text(hours)
          $('#tA-minutes').text(minutes)
          $('#tA-seconds').text(seconds)
        } else {
          days = days ? days + "d " : ""
          hours = hours ? (hours>=10? hours: '0'+hours) + "h " : "00h"
          minutes = minutes ? (minutes>=10? minutes: '0'+minutes) + "m " : "00m"
          seconds = seconds ? (seconds>=10? seconds: '0'+seconds) + "s " : "00s"
          const result =  days + hours + minutes + seconds;
          tempArray = document.getElementsByClassName(address + "-timedAuction-Countdown");
          for(let i=0;i<tempArray.length;i++){
            tempArray[i].innerHTML = result;
          }
        }

        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(timers[_this.id]);
          if(collectionShowPage) {
            $('#timedAuction-countdown, #collection-actions').hide();
            $('.end-action').show();
            document.getElementById('instant-price').style.display = "none";
          }
          else {
            tempArray = document.getElementsByClassName(address +"-timedAuction-Countdown");
            for(let i=0;i<tempArray.length;i++){
              tempArray[i].innerHTML = "Expired";
            }
            $('.'+ address + "-timedAuction-Headline").hide();
          }
        }
      }
    }, 1000);
  }

  $(document).on("click", ".comment-reply", function(e) {
    e.preventDefault()
    const id = $(this).data("id")
    const commentInput = $(`#comment_input_${id}`)
    const message = commentInput.html()
    if(!message) {
      toastr.error('Please enter the comment')
      return
    }
    const commentSection = $(`#comment_section_${id}`)
    createComment({
      parent_id: id,
      message,
      curator: commentSection.data("address"),
    }, commentSection, commentInput, 'update')
  })

  $(document).on("click", ".comment-create", function(e) {
    e.preventDefault()
    const id = $(this).data('id')
    const commentInput = $(`#comment-input-${id}`)
    const commentSection = $(`#comment-section-${id}`)
    const message = commentInput.html()
    if(!message) {
      toastr.error('Please enter the comment')
      return
    }
    createComment({
      parent_id: null,
      message,
      curator: commentInput.data("address"),
    }, commentSection, commentInput)
  })

  $(document).on("click", ".comment-reply-show", function(e) {
    e.preventDefault()
    if($('#comment-section').hasClass( "disabled" )) {
      return
    }
    const id = $(this).data("id")
    $(`#comment_reply_div_${id}`).toggleClass('hide');
  })

  $(document).on("click", '.comment-delete', function(e) {
    e.preventDefault();
    const id = $(this).data('id');
    const address = $(`#comment_section_${id}`).data('address')
    $.ajax({
      url: `/comments/${id}`,
      type: "DELETE",
      dataType: 'json',
      success: function(data) {
       if(!data.status) {
        toastr.error(data.text)
        return
       }
      const countTag = $(`#comment-count-${address}`)
      if(countTag.length) {
        countTag.text(`${data.count} Comments`)
      }
       $(`#comment_block_${id}`).remove()
      },
      error: function(data) {
        toastr.error('Something went wrong')
      },
    })

  });

  $('#report_it').click(function () {
    const type = $('#collection_report_type').val()
    const path = $('#collection_report_data_type').val()
    if(!type) {
      return toastr.error('Please select report type')
    }
    $.ajax({
      url: path,
      type: 'post',
      async: false,
      data: { type },
      success: function (respVal) {
        $('.modal__close').click()
        toastr.success(respVal.message)
      }
    });
  })

  $(document).on('click', '.submit-ticket', function(e) {
    e.preventDefault();
    var name = $('#ticket_name').val();
    var email = $('#ticket_email').val();
    var message = $('#ticket_message').val();
    var phone =  $('#ticket_phone').val();
    var reason =  $('#ticket_reason').val();
    var time_to_call =  $('#ticket_time_to_call').val();
    if (name == ''){
      toastr.error('Please Enter name');
      return
    }
    if (!isEmail(email)){
      toastr.error('Please Enter valid email');
      return
    }
    if (message == ''){
      toastr.error('Please Enter message');
      return
    }
    $.ajax({
      url: '/tickets',
      type: 'POST',
      async: false,
      data: { ticket:  {name: name,
        email: email,
        message: message,
        phone: phone,
        reason: reason,
        time_to_call: time_to_call} },
      error: function(xhr, status, error) {
        toastr.error('Invalid request');
      }
    });
  });


});

window.preventNegativeNumbers = function(inputs){
  if(!inputs) {return;}
  inputs = 'HTMLCollection' === inputs.constructor.name ? Array.from(inputs) : Array.of(inputs)
  inputs.forEach(negativeNumberPrevent)
}

function negativeNumberPrevent(input) {
  input.addEventListener('keypress', function(e) {
    var key = !isNaN(e.charCode) ? e.charCode : e.keyCode;
    function keyAllowed() {
      var keys = [8,9,13,16,17,18,19,20,27,46,48,49,50,
                  51,52,53,54,55,56,57,91,92,93];
      if (key && keys.indexOf(key) === -1)
        return false;
      else
        return true;
    }
    if (!keyAllowed())
      e.preventDefault();
  }, false);

  // EDIT: Disallow pasting non-number content
  input.addEventListener('paste', function(e) {
    var pasteData = e.clipboardData.getData('text/plain');
    if (pasteData.match(/[^0-9]/))
      e.preventDefault();
  }, false);
}

function onlyNumberKey(evt) {
  if(!evt) return;
  // Only ASCII character in that range allowed
  var ASCIICode = (evt.which) ? evt.which : evt.keyCode
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
      return false;
  return true;
}

window.copyToClipboard = function(text) {
  if(window.navigator?.clipboard) {
    navigator.clipboard.writeText(text);
  }else if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  }
  else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
        return document.execCommand("copy");  // Security exception may be thrown by some browsers.
    }
    catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return prompt("Copy to clipboard: Ctrl+C, Enter", text);
    }
    finally {
      document.body.removeChild(textarea);
    }
  }
}

function createComment(comment, commentSection, commentInput = null) {
  if($('#comment-section').hasClass( "disabled" )) {
    return
  }
  $.ajax({
    url: "/comments",
    type: "post",
    dataType: "json",
    data: {
      comment
    },
    success: function(data) {
      if(commentInput) {
        commentInput.html('')
      }
      commentSection.append(data.html)
      const countTag = $(`#comment-count-${comment.curator}`)
      if(countTag.length) {
        countTag.text(`${data.count} Comments`)
      }
      ReactRailsUJS.mountComponents()
      window.at_who(window.users)
    },
    error: function(data) {
      toastr.error('Something went wrong')
    },
  })
}

$(document).on('click', 'button.talkchart_close', function() {
  $('.converstation-section').hide();
});

$(document).on('click', '.open_chat_btn', function(e) {
  e.preventDefault();
  var customer_email = $('.cust_email').val();
  if (!isEmail(customer_email)){
    toastr.error('Please Enter valid email');
    return
  } else {
    $.ajax({
      url: 'tickets/get_ticket',
      type: 'GET',
      async: false,
      dataType: 'json',
      data: { email: customer_email },
      success: function(data, status, xhr) {
        $('.converstation-section').show();
        loadChat(data.data);
      },
      error: function(xhr, status, error) {
        toastr.error('Invalid request');
        $('.converstation-section').hide();
      }
    })

  }
});

function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

$(document).on('click', '#btn_copy', function() {
  var copyTexted = $(".address_layer").text();
  var copyTexted = $(".ref_con").val();
  copyToClipboard(copyTexted);
});

function copyToClipboard(text) {
  var sampleTextarea = document.createElement("textarea");
  document.body.appendChild(sampleTextarea);
  sampleTextarea.value = text; //save main text in it
  sampleTextarea.select(); //select textarea contenrs
  document.execCommand("copy");
  document.body.removeChild(sampleTextarea);
  toastr.success('Copied successfully.').fadeOut(5000);
}
