var modal_steps;
const tradeContractAddress = gon.tradeContractAddress;
const transferProxyContractAddress = gon.transferProxyContractAddress;
const wethAddress = gon.wethAddress;
var mint_data;

function loadLaunchpads(page) {
  $.ajax({
    url: "/launchpads",
    type: "get",
    dataType: "script",
    data: { page: page },
    complete: function(){
      loaderHide()
    }
  })
}


async function getLaunchpadSign(sign_data, type) {
  let sign = null;
  $.ajax({
    url: `/launchpads/get_sign`,
    type: "GET",
    async: false,
    data: { sign_data: sign_data, type: type },
    dataType: "json"
  })
  .done(function (data) {
    return sign = {sign: data['signature']}
  })
  .fail(function (jqXHR, textStatus) {
    console.log("Nonce failed. Please contact support");
  });
  return sign
}

async function dBSaveMintTransaction(launchpad_id, token_id, transaction_hash, nonce) {
  $.ajax({
    url: `/launchpads/${launchpad_id}/save_mint_transaction`,
    type: "POST",
    async: false,
    data: { token_id: token_id, transaction_hash: transaction_hash, nonce: nonce },
    dataType: "json"
  })
  .done(function (data) {
    toastr.success('Collection minted succcessfully.')
    modal_steps.success();
    window.location.href = `/launchpads/${launchpad_id}`
  })
  .fail(function (jqXHR, textStatus) {
    console.log("Collection mint failed. Please contact support");
  });
}


// (assetOwner, buyingAssetType, buyingAssetAddress, tokenId, unitPrice, buyingAssetQty,
//                                           paymentAmt, paymentAssetAddress, decimals, sellerSign, collectionId, tokenURI, wallet_address, royalties, sharedCollection, supply, trade_address) {

window.mintCollections = async function mintCollections(data){
  try {
    if(data == null) {
      throw "Something went wrong, please try again later!";
    }
    mint_data = data;
    // let account = getCurrentAccount();
    // let contractData = await getFeeDataFromProvider(account);
    // const contract = await fetchContract(tradeContractAddress, 'trade_contract');

    // let signHash = await getLaunchpadSign(data.sign_data, 'owner');
    // let sign = splitSign(signHash.sign, data.nonce);
    
    // let gaslimit = await contract.estimateGas.mintNft(creator, tokenURI, royaltyFee, sign, {});
    // contractData.gasLimit = gasLimit;

    // receipt = await contract.mintNft(creator, tokenURI, royaltyFee, sign, contractData);

    // receipt = await receipt.wait();
    // const tokenId = parseInt(receipt.logs[1].topics[3]);
    // const transaction_hash = receipt.events[0].transactionHash;
    // dBSaveMintTransaction(data.id, tokenId, transaction_hash, data.nonce)

    // paymentAmt = roundNumber(mulBy(paymentAmt, 10 ** decimals), 0);
    // unitPrice = roundNumber(mulBy(unitPrice, 10 ** decimals), 0);
    // const buyingAssetType = 'LazyERC721';
    // const contract = await getContract(tradeContractAddress(), 'trade');
    // const nonce_value = getContractSignNonce(collectionId, 'collection_instantSale');
    // const account = await getCurrentAccount();
    // const skipRoyalty = gon.collection_data.is_imported;
    // const orderStruct = [
    //   assetOwner,
    //   account,
    //   paymentAssetAddress,
    //   buyingAssetAddress,
    //   buyingAssetType,
    //   unitPrice,
    //   skipRoyalty,
    //   paymentAmt,
    //   tokenId,
    //   supply,
    //   buyingAssetQty,
    //   tokenURI,
    //   royalties,
    //   wallet_address
    // ]
    // // ownerSign -> selleraddress & URI
    // const ownerSign = await sign_metadata_with_creator(collectionId, trade_address);
    // let contractData = await getFeeDataFromProvider();
    // let gasLimit = await contract.estimateGas.mintAndBuyAsset(
    //   orderStruct,
    //   splitSign(sellerSign, nonce_value),
    //   splitSign(ownerSign['sign'], ownerSign['nonce'])
    // );
    // contractData.gasLimit = gasLimit;
    // console.log(ownerSign);
    // let receipt = await contract.mintAndBuyAsset(
    //   orderStruct,
    //   splitSign(sellerSign, nonce_value),
    //   splitSign(ownerSign['sign'], ownerSign['nonce']),
    //   contractData
    // );
    // receipt = await receipt.wait();
    // const type = buyingAssetType == 3 ? 'lazy_single' : 'lazy_multiple';
    // tokenId = getTokenId(receipt, 'lazy_single')
    // await updateCollectionBuy(collectionId, buyingAssetQty, receipt.transactionHash, tokenId)
    // return window.buyPurchaseSuccess(collectionId)

    dBSaveMintTransaction(data.id, "tokenId", "transaction_hash", data.nonce)
  } 
  catch (err) {
    modal_steps.failure();
    console.log(err);

    if(typeof(err) === 'object'){
      toastr.error(err.message);
    }
    else {
      toastr.error(err); 
    }
  }
}

window.approveERC20NFT = async function approveERC20NFT(data){
  try {
    modal_steps.start();

    if(data == null) {
      throw "All the NFTs were minted, please try again later!";
    }
    var amount = data.order[6];
    const approveERC2 = await fetchContract(wethAddress, 'weth');
    var account = window.ethereum.selectedAddress;
    const balance = await approveERC2.allowance(account, transferProxyContractAddress);
    amount = BigInt(parseInt(balance) + parseInt(amount)).toString();

    //TODO:
    // if user balance less than amount then convert token

    var receipt = await approveERC2.approve(transferProxyContractAddress, amount, {from: account});

    receipt = await receipt.wait();
    modal_steps.success();

    mintCollections(data);
  }
  catch (err) {
    modal_steps.failure();
    console.log(err);

    if(typeof(err) === 'object'){
      toastr.error(err.message);
    }
    else {
      toastr.error(err); 
    }
  }
}

function dbCreateCollection($form){
  modal_steps.start();

  $.ajax({
    url: $form.prop('action'),
    async: false,
    type: $form.prop('method'),
    data: $form.serializeArray(),
    dataType: "JSON",
    success: function (data) {
      approveERC20NFT(data)
    },
    error: function (jqXHR, textStatus) {
      console.log("Failed to update token id");
    }
  });
  
}

$(document).ready(function (argument) {

  $("form#buy_launchpad_form").submit(function( event ) {

    if (!gon.session) {
      toastr.error('Please sign in for minting');
      window.location.href = "/";
    }
    else {

      if ($(this).data("free-mint") && $(this).data("limit") < 1) {
        toastr.error('Your free mint limit exceeds');
      }

      $('input[type=submit]', this).removeAttr('disabled', 'disabled');
      modal_steps = modalSteps.load('mintNftsModal', ['approveowner', 'mintnft']);
      dbCreateCollection($(this));
    }
    event.preventDefault();
    return  false;
  });

  $(document).on("click", ".approveownerRetry", function () {
    dbCreateCollection($("form#buy_launchpad_form"));
  });

  $(document).on("click", ".mintnftRetry", function () {
    mintCollections(mint_data);
  });

  $(document).on("click", "#launchpads-load-more", function () {
    loadLaunchpads(parseInt($(this).data('page')) + 1);
  });

});